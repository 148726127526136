export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	LOGIN,
	REGISTRATION,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_CONFIRM,
	RESET_PASSWORD,
	ERROR,
	LEAGUE_JOINED,
	LEAGUE_REMOVE_USER,
	LEAGUE_LEAVE,
	LEAGUE_REMOVE,
	PASSWORD_CHANGED,
	ACCOUNT_UPDATED,
	CONFIRM,
	DEACTIVATE,
	DRIVER_PROFILE,
	NO_HEADS_SAVE,
	TRADE_CONFIRM,
	TRADE_RESET,
	USER_EXISTS,
	TEAM_SAVED,
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
	Cancelled = "cancelled",
}

export enum LeaguePrivacy {
	Public = "public",
	Private = "private",
}

export enum LeagueType {
	Overall = "overall",
	Regular = "regular",
	//CLASSIC = "regular",
	H2H = "h2h",
}

export enum SocialNetwork {
	FACEBOOK,
	TWITTER,
	MOBILE,
}

export enum ShareType {
	LEAGUE = "fantasy_league",
	General = "general",
}

export enum TeamPosition {
	//Commenting for future reference
	//Hunter = 1,
	QLD = 1,
	//Western = 2,
	NSW = 2,
	//Riverina = 3,
	VIC = 3,
	//Metropolitan = 4,
	WA = 4,
	SA = 5,
	TAS = 6,
}

export enum RegionGroup {
	QLD = "QLD",
	NSW = "NSW",
	VIC = "VIC",
	WASAT = "WASAT",
}

export enum DriverStatus {
	Available = "available",
	Unavailable = "unavailable",
	Eliminated = "eliminated",
}

export enum SalaryCapAction {
	Add,
	Subtract,
}

export enum HeadsPosition {
	Captain = "captain",
	ViceCaptain = "viceCaptain",
}

export enum H2HFinalType {
	Top4 = "top4",
	Top8 = "top8",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum TradeSideType {
	tradeOut = "tradeOut",
	tradeIn = "tradeIn",
}

export enum TeamStats {
	Price = "price",
	Points = "points",
}

export enum DriverPriceChange {
	Up = "up",
	Down = "down",
	Same = "same",
}

export enum Locale {
	EN_US = "en-US",
	PT_BR = "pt-BR",
}

export enum Language {
	EN = "en",
	PR = "pt",
}
