import React, {useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IFormLoginController} from "views/components/forms/login/form_login.controller";
import {observer} from "mobx-react";
import {
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	TextField,
	Typography,
} from "@mui/material";
import {ButtonViewPassword, Form} from "views/components/forms/common/forms_common.component";
import {LinkStyled} from "views/components/links/link_styled/link_styled.component";
import styled from "styled-components";
import {
	EMAIL_REGEXP_STR,
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	PASSWORD_REGEXP_STR,
} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";
import {Navigate} from "react-router-dom";

const Link = styled(LinkStyled)`
	font-size: 14px;
`;

const inputProps = {className: FORM_VALIDATION_ELEMENT_CLASSNAME};

export const FormLogin: React.FC = observer(() => {
	const controller = useViewController<IFormLoginController>(Bindings.FormLoginController);

	const {
		handleSubmitForm,
		handleFormChange,
		goToRegistration,
		goToForgotPassword,
		formErrors,
		error,
		isFormLocked,
		tmpUserData,
		isAuthorized,
	} = controller;

	const labelEmail = "Email";
	const labelPassword = "Password";
	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const handleToggleShowPassword = () => setPasswordVisible(!isPasswordVisible);

	const errorEmail = formErrors["email"];
	const errorPassword = formErrors["password"];

	if (isAuthorized) {
		// implement navigate function similar to create league
		return <Navigate to={"/my-account"} replace />;
	}

	return (
		<Form onSubmit={handleSubmitForm} onChange={handleFormChange} noValidate>
			<Typography sx={{fontWeight: 700, fontSize: 16, textAlign: "center"}}>
				Log in to your account
			</Typography>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					defaultValue={tmpUserData?.email}
					label={labelEmail}
					placeholder={labelEmail}
					type="email"
					name="email"
					required
					inputProps={{
						pattern: EMAIL_REGEXP_STR,
						className: inputProps.className,
					}}
					error={Boolean(errorEmail)}
					helperText={errorEmail}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					defaultValue={tmpUserData?.password}
					label={labelPassword}
					placeholder={labelPassword}
					type={isPasswordVisible ? "text" : "password"}
					name="password"
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isPasswordVisible}
								onClick={handleToggleShowPassword}
							/>
						),
					}}
					error={Boolean(errorPassword)}
					helperText={errorPassword}
				/>
			</FormControl>
			<Typography sx={{textAlign: "right", fontWeight: 500}}>
				<Link onClick={goToForgotPassword}>Forgot Password?</Link>
			</Typography>
			<Exist when={Boolean(error)}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
			<Button
				size="large"
				fullWidth
				variant="contained"
				disabled={isFormLocked}
				style={{fontWeight: 600, fontSize: 16}}
				type="submit">
				<Exist when={!isFormLocked}>Log In</Exist>
				<Exist when={isFormLocked}>
					<CircularProgress size={20} color="inherit" sx={{mr: 1.5}} />
					Loading...
				</Exist>
			</Button>
			<Typography
				sx={{
					textAlign: "center",
					fontSize: 14,
					fontFamily: "var(--fontFamilyBase)",
					fontWeight: 400,
				}}>
				New? <Link onClick={goToRegistration}>Register Now</Link>
			</Typography>
		</Form>
	);
});
