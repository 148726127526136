import React from "react";
import {ButtonPrimary, ModalCloseButton} from "views/components/button";
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {IModalUserExistsController} from "views/components/modals/modal_user_exists/modal_user_exists.controller";
import WarningIcon from "@mui/icons-material/Warning";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

const Title = styled.h2`
	font-family: var(--fontFamilyBase);
	color: var(--primaryTextColor);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
`;

const Text = styled.p`
	font-family: var(--fontFamilyBase);
	color: var(--primaryTextColor);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
`;

const SubmitButton = styled(ButtonPrimary)`
	height: 52px;
	text-transform: uppercase;
	margin: 14px 0;
	width: 100%;
`;

const SModalBox = styled(ModalBox)`
	max-width: 600px;
`;

const SModalContent = styled(ModalContent)`
	margin: 0 auto;
	max-width: 360px;
	padding: 28px 28px 20px 28px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;

	h2 {
		line-height: 130%;
	}
`;

const IconWrapper = styled.div`
	margin-top: 32px;
	margin-bottom: 8px;
`;

export const ModalUserExists: React.FC = observer(() => {
	const navigate = useNavigate();
	const {isOpen, toLogin, close} = useViewController<IModalUserExistsController>(
		Bindings.ModalUserExistsController,
		{navigate}
	);
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	if (!isOpen) {
		return null;
	}

	return (
		<Modal open={isOpen} onClose={close}>
			<SModalBox>
				<SModalContent>
					<ModalCloseButton onClick={close}>
						<CloseIcon />
					</ModalCloseButton>
					<IconWrapper>
						<WarningIcon color="warning" fontSize="large" />
					</IconWrapper>
					<Title>
						{i18n.t(
							`registration.form_error.existing_user_header`,
							"Looks like you’re an existing Harness Racing Autralia Fantasy player."
						)}
					</Title>
					<Text>
						{i18n.t(
							`registration.form_error.existing_user_body`,
							"You can login with those same details here:"
						)}
					</Text>
					<SubmitButton onClick={toLogin}>Login</SubmitButton>
				</SModalContent>
			</SModalBox>
		</Modal>
	);
});
