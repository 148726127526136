import {ViewController} from "data/types/structure";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {ShareUtility} from "data/utils/social_share/social_share.utility";
import {ShareType, SocialNetwork} from "data/enums";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface ITeamSocialSharingController extends ViewController {
	shareTwitter: () => void;
	shareFacebook: () => void;
	shareNative: () => void;
	openSharing: () => void;
	closeSharing: () => void;

	get isSharingAvailable(): boolean;

	get isSharingOpen(): boolean;
}

@injectable()
export class TeamSocialSharingController implements ITeamSocialSharingController {
	private _shareMessage: string = `I've built my team for Harness Racing Australia for Race Week {X}, click here to join me and take part in the action!`;
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isSharingOpen: boolean = false;

	get isSharingOpen(): boolean {
		return this._isSharingOpen;
	}

	get isSharingAvailable(): boolean {
		return this._userStore.isAuthorized;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	openSharing = (): void => {
		runInAction(() => {
			this._isSharingOpen = true;
		});
	};

	@action
	closeSharing = (): void => {
		runInAction(() => {
			this._isSharingOpen = false;
		});
	};

	shareFacebook = (): void => {
		ShareUtility.share({
			message: this.getPreparedMessage(),
			type: ShareType.General,
			socialNetwork: SocialNetwork.FACEBOOK,
			round: this._roundsStore.currentRound?.id || 1,
		});
	};

	shareNative = (): void => {
		ShareUtility.share({
			message: this.getPreparedMessage(),
			type: ShareType.General,
			socialNetwork: SocialNetwork.MOBILE,
		});
	};

	shareTwitter = (): void => {
		ShareUtility.share({
			message: this.getPreparedMessage(),
			type: ShareType.General,
			socialNetwork: SocialNetwork.TWITTER,
		});
	};

	private getPreparedMessage(): string {
		const round = this._roundsStore.currentRound;
		return this._shareMessage.replace("{X}", String(round?.id || 1));
	}
}
