import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {ICountry} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {formatPhoneNumber, getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import {get} from "lodash";
import React from "react";
import type {ITitleStore} from "data/stores/title/title.store";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import type {IState, IStatesStore} from "data/stores/states/states.store";
import {POSTCODE_COUNTRY} from "data/constants";

export interface IMyAccountFormValues {
	firstName?: string;
	lastName?: string;
	email?: string;
	teamName?: string;
	isNotificationsEnabled?: boolean | undefined;
	optIn?: boolean | undefined;
	state?: string | undefined;
	country?: string;
	phone?: string;
}

interface IUpdateForm extends HTMLFormElement {
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	email: HTMLInputElement;
	teamName: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
	optIn: HTMLInputElement;
	phone: HTMLInputElement;
	country: HTMLInputElement;
	state: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	logout: () => void;
	formErrors: Record<string, string>;
	handleFormChange: (e: React.ChangeEvent<HTMLFormElement>) => void;
	submit: (e: React.SyntheticEvent<IUpdateForm>) => void;
	formValues: IMyAccountFormValues;
	error: string | undefined;
	deactivate: () => void;
	openZendesk: () => void;
	setCountry: (value: string) => void;

	setState: (value: string) => void;

	handleInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	get countries(): ICountry[];

	get userName(): string;

	get user(): IUser | undefined;

	get isLoading(): boolean;

	get isSubmitDisabled(): boolean;

	get statesList(): IState[];

	get countryList(): ICountry[];

	get isAU(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper,
		@inject(Bindings.TitleStore) public _titleStore: ITitleStore,
		@inject(Bindings.StatesStore) private readonly _statesStore: IStatesStore,
		@inject(Bindings.CountriesStore) private _countryStore: ICountriesStore
	) {
		makeAutoObservable(this);
	}

	@observable _error: string | undefined = undefined;

	get error() {
		return this._error;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isSubmitDisabled() {
		return this._isLoading || !this.isChanged;
	}

	@observable _formValues = {
		firstName: this._userStore.user?.firstName,
		lastName: this._userStore.user?.lastName,
		email: this._userStore.user?.email,
		teamName: this._userStore.user?.teamName,
		isNotificationsEnabled: this._userStore.user?.isNotificationsEnabled,
		optIn: this._userStore.user?.optIn,
		state: this._userStore.user?.state,
		phone: formatPhoneNumber(this._userStore.user?.phone),
		country: this._userStore.user?.country,
	};

	// private formatPhoneNumber(numberStr ?: string) {

	// 	if (!numberStr) {
	// 		numberStr = "000000000"; // Default to 9 zeros
	// 	}
	// 	numberStr = numberStr.toString();

	// 	// Pad with leading zero if necessary
	// 	if (numberStr.length < 9) {
	// 		numberStr = numberStr.padStart(9, '0');
	// 	}

	// 	// Format the number into "04XX-XXX-XXX"
	// 	return `04${numberStr.slice(0, 2)}-${numberStr.slice(2, 5)}-${numberStr.slice(5, 9)}`;
	// }
	get formValues() {
		return this._formValues;
	}

	get countries() {
		return this._countriesStore.list;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get userName() {
		return this._userStore.fullName;
	}

	get user() {
		return this._userStore.user;
	}

	get isChanged() {
		let changed = false;
		const user = this.user;
		Object.keys(this.formValues).forEach((fieldName) => {
			const formValue = get(this.formValues, fieldName) as string | boolean;
			const userValue = get(user, fieldName) as string | boolean;
			if (formValue !== userValue) {
				changed = true;
			}
		});
		return changed;
	}

	get statesList() {
		return this._statesStore.list;
	}

	get countryList() {
		return this._countryStore.list;
	}

	get isAU() {
		// If nothing selected - default value AU
		return this._formValues?.country === POSTCODE_COUNTRY;
	}

	handleUsernameFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;

		this.handleInputFieldChange(event);
		void this.checkUsernameUnique(name, value);
	};

	handleInputFieldChange = ({
		target: {name, value, checked},
	}: React.ChangeEvent<HTMLInputElement>) => {
		if (["password", "confirmPassword"].includes(name)) {
			this._validationHelper.clearFormFieldError("password");
			this._validationHelper.clearFormFieldError("confirmPassword");
		} else {
			this._validationHelper.clearFormFieldError(name);
		}

		const valueToUse = ["notifications", "terms", "isDriver"].includes(name) ? checked : value;
		this._userStore.setTmpUserData({[name]: valueToUse});
	};
	setCountry = (value: string) => {
		this._formValues.country = value;

		if (value !== POSTCODE_COUNTRY) {
			this._formValues.state = undefined;
		}
	};
	setState = (value: string) => {
		this._validationHelper.clearFormFieldError("state");
		this._formValues.state = value;
	};

	@action
	public handleFormChange = ({
		target: {name, value, checked},
	}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._validationHelper.clearFormFieldError(name);
		const valueToUse = ["isNotificationsEnabled", "optIn"].includes(name)
			? Boolean(checked)
			: String(value);

		this._formValues = {
			...this._formValues,
			[name]: valueToUse as string,
		};
	};

	@action submit = async (event: React.SyntheticEvent<IUpdateForm>) => {
		event.preventDefault();
		this._error = undefined;

		if (this.formErrors["teamName"]) return;

		const {checkValidity} = this._validationHelper;
		const form = event.currentTarget;

		const canSubmit = checkValidity(form);
		const isStateOk = await this.checkState("state", this._formValues.state);

		if (!canSubmit || !isStateOk) return;

		this.updateLoadingState(true);

		let isSubmitted;

		const payload = {
			firstName: this._formValues.firstName,
			lastName: this._formValues.lastName,
			email: this._formValues.email,
			teamName: this._formValues.teamName,
			isNotificationsEnabled: this._formValues.isNotificationsEnabled,
			optIn: this._formValues.optIn,
			state: this._formValues.state,
			phone: Number(this._formValues.phone.replace(/\D/g, "")),
			country: this._formValues.country,
		};

		if (this._formValues.teamName === this._userStore.user?.teamName) {
			// Remove teamName if it hasn't changed
			delete payload.teamName;
		} else {
			// Check for unique team name if it has changed
			isSubmitted = await this.checkUsernameUnique(
				"teamName",
				this._formValues?.teamName || ""
			).catch(this.onError);
			if (!isSubmitted) {
				this.updateLoadingState(false);
				return;
			}
		}

		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(this.onFinally);
	};

	@action deactivate = () => {
		void this._modalsStore.showModal(ModalType.DEACTIVATE);
	};

	public openZendesk(): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		window.zE?.activate();
	}

	dispose(): void {
		return;
	}

	logout = () => void this._userStore.logout();

	init(params: void): void {
		void this._countriesStore.fetchCountries();
		void this._titleStore.setTitle("My Account");
		void this._statesStore.fetchStates();
	}

	private checkState(fieldName: string, fieldValue?: string): Promise<boolean> {
		if (!this.isAU) {
			delete this._formValues.state;
			return Promise.resolve(true);
		}
		if (!fieldValue && this.isAU) {
			this._validationHelper.setFormFieldError(fieldName, "State is required");
			return Promise.resolve(false);
		}

		return Promise.resolve(true);
	}

	private checkUsernameUnique(fieldName: string, fieldValue: string): Promise<boolean> {
		if (!fieldValue) return Promise.resolve(false);

		return this._userStore
			.checkUsername({
				username: fieldValue,
			})
			.then((error) => {
				if (error) {
					this._validationHelper.setFormFieldError(fieldName, error);
					return false;
				}

				return true;
			})
			.catch((err: Error) => {
				trackSentryErrors(err, {}, "form registration - check username");
				this._validationHelper.setFormFieldError(fieldName, err.message);

				return false;
			});
	}

	private onSuccess = () => {
		this.updateLoadingState(false);
		this._modalsStore.showModal(ModalType.ACCOUNT_UPDATED);
	};

	@action private onError = (event: AxiosError<IAxiosApiError, unknown>) => {
		this._error = getErrorMessageFromAxiosResponse(event);
		this.updateLoadingState(false);
	};

	private onFinally = () => {
		this.updateLoadingState(false);
	};

	@action
	private updateLoadingState(value: boolean) {
		runInAction(() => {
			this._isLoading = value;
		});
	}
}
