import {
	HeadsPosition,
	ModalType,
	RoundStatus,
	SalaryCapAction,
	TeamPosition,
	TeamStats,
	TradeSideType,
} from "data/enums";
import {
	ITeamLineup,
	type IDriver,
	type IFullTrade,
	type IRegion,
	type ITeam,
	type IUserTrade,
} from "data/types/team";
import {action, makeAutoObservable, observable, runInAction, toJS} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";
import type {IJSONProvider, IRound} from "data/providers/json/json.provider";
import {AVAILABLE_TRADES_COUNT, SALARY_CAP} from "data/constants";
import type {IDriverStore} from "data/stores/driver/drivers.store";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {chain, identity, intersection, isNull} from "lodash";

export const default_lineUp: ITeamLineup = {
	[TeamPosition.QLD]: [0, 0, 0, 0],
	[TeamPosition.NSW]: [0, 0, 0, 0],
	[TeamPosition.VIC]: [0, 0, 0, 0],
	[TeamPosition.WA]: [0, 0],
	[TeamPosition.SA]: [0, 0],
	[TeamPosition.TAS]: [0, 0],
	reserve: {
		[TeamPosition.QLD]: 0,
		[TeamPosition.NSW]: 0,
		[TeamPosition.VIC]: 0,
		[TeamPosition.WA]: 0,
		[TeamPosition.SA]: 0,
		[TeamPosition.TAS]: 0,
	},
	captain: 0,
	viceCaptain: 0,
};

export interface ITeamStore {
	get team(): ITeam;

	get regions(): IRegion[];

	get isLoading(): boolean;

	get isTeamLoaded(): boolean;

	get isTeamLoading(): boolean;

	get salaryCap(): number;

	get isPoolOpen(): boolean;

	get getDriversInTeam(): number[];

	get teamLineUp(): Record<TeamPosition, number[]>;

	get hasTeamBeenChanged(): boolean;

	get isHeadsSet(): boolean;

	get isTeamNotSaved(): boolean;

	get isTradingState(): boolean;

	get fieldStats(): TeamStats;

	set fieldStats(value: TeamStats);

	getIsDriverInReserve(driverId?: number): boolean;

	setTeamName(name: string): void;

	fetchRegions(): void;

	getRegionGroupById(id: number): string | null | undefined;

	getRegionNameById(id: number): string | null | undefined;

	addDriverToTeam(driverId: number): boolean;

	removeDriverFromTeam(driverId: number | null): boolean;

	getIsDriverInTeam(driverId: number | null): boolean;

	getLineupForRow(row: TeamPosition): number[];

	toggleDriverPool(open: boolean): void;

	clearTeam(): void;

	fetchTeam(roundId?: number): void;

	saveTeam(): Promise<void>;

	autoPick(): Promise<void>;

	setAsCaptain(driverId?: number): void;

	setAsViceCaptain(driverId?: number): void;

	removeRole(role: HeadsPosition): void;

	getIsDriverRole(driverId?: number, role?: HeadsPosition): boolean;

	getIsHeadsAndCanBeToggle(driverId?: number): boolean;

	fetchTeamForSelectedRound(): void;

	get isTeamInLockout(): boolean;

	get isTeamScoring(): boolean;

	hasGroupDrivers(round: IRound): boolean;

	get leadersIdDoubleScore(): number | null;

	get isTradesAvailable(): boolean;

	get isTradesRound(): boolean;

	tryAddDriverToTrade(driver: IDriver, side: TradeSideType): void;

	removeDriverFromTrade(driverId: number, side: TradeSideType): void;

	get userTrades(): IUserTrade[];

	get teamFullTrades(): IFullTrade[];

	isDriverOnTrade(id?: number): boolean;

	resetTrades(): void;

	makeTrades(): void;

	resetMadeTrades(): void;

	get madeTradeDrivers(): IFullTrade[];

	get tradesLeft(): number;

	get totalTrades(): number;

	resetTrade(index: number): void;

	isOutTradesAvailable(driver: IDriver): boolean;

	isInTradesAvailable(driver: IDriver): boolean;

	addToSwap(driver: IDriver): void;

	isOnSwap(driver: IDriver): boolean;

	canBeSwapped(driver: IDriver): boolean;

	swapDriver(driver: IDriver): void;

	setReserveId(id: number): void;

	get isOverBudget(): boolean;

	get isSwapInProgress(): boolean;
	get regionGroups(): string[];
	get reserveId(): number;
}

@injectable()
export class TeamStore implements ITeamStore {
	_initialTeamValue: number = 0;
	@observable _regions: IRegion[] = [];
	@observable _team: ITeam = {
		name: "",
		value: 0,
		startRoundId: null,
		isComplete: false,
		availableTrades: 0,
		salaryCap: SALARY_CAP,
		totalTrades: AVAILABLE_TRADES_COUNT,
		lineup: {
			[TeamPosition.QLD]: [0, 0, 0, 0],
			[TeamPosition.NSW]: [0, 0, 0, 0],
			[TeamPosition.VIC]: [0, 0, 0, 0],
			[TeamPosition.WA]: [0, 0],
			[TeamPosition.SA]: [0, 0],
			[TeamPosition.TAS]: [0, 0],
			reserve: {
				[TeamPosition.QLD]: 0,
				[TeamPosition.NSW]: 0,
				[TeamPosition.VIC]: 0,
				[TeamPosition.WA]: 0,
				[TeamPosition.SA]: 0,
				[TeamPosition.TAS]: 0,
			},
			captain: 0,
			viceCaptain: 0,
		},

		trades: [],
	};
	@observable _userTrades: IUserTrade[] = [];
	@observable private _fieldStats: TeamStats = TeamStats.Price;
	@observable private _isTeamNotSaved: boolean = false;
	@observable private _hasTeamBeenChanged: boolean = false;
	@observable private _isPoolOpen: boolean = false;
	@observable private _salaryCap: number = SALARY_CAP;
	@observable private _isTeamLoading: boolean = false;
	@observable private _isLoading: boolean = false;
	@observable private _swap: IDriver | null = null;
	@observable _reservedId: number = 0;
	@observable _previousReserveId: number = 0;
	@observable _reserveDriver: number = 0;

	constructor(
		@inject(Bindings.TeamApiProvider) private _teamProvider: ITeamApiProvider,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.DriverStore) private _driversStore: IDriverStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public get reserveId(): number {
		return this._reservedId;
	}
	setReserveId(id: number) {
		if (this._reservedId > 3) {
			this._reservedId = id;
		}
	}

	public get fieldStats(): TeamStats {
		return this._fieldStats;
	}

	public set fieldStats(value: TeamStats) {
		this._fieldStats = value;
	}

	get hasTeamBeenChanged(): boolean {
		return this._hasTeamBeenChanged;
	}

	get isPoolOpen(): boolean {
		return this._isPoolOpen;
	}

	get salaryCap(): number {
		return this._salaryCap - this.team.value;
	}

	get isTeamLoading(): boolean {
		return this._isTeamLoading;
	}

	get isTeamLoaded(): boolean {
		return !this._isTeamLoading && this.team.id !== undefined;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get regions(): IRegion[] {
		return this._regions;
	}

	get regionGroups() {
		return Array.from(new Set(this._regions.map((region) => region.regionGroup)));
	}
	get team(): ITeam {
		return this._team;
	}

	get swap(): IDriver | null {
		return this._swap;
	}

	set swap(driver: IDriver | null) {
		this._swap = driver;
	}

	get teamLineUp(): Record<TeamPosition, number[]> {
		return {
			[TeamPosition.QLD]: this.team.lineup[TeamPosition.QLD],
			[TeamPosition.NSW]: this.team.lineup[TeamPosition.NSW],
			[TeamPosition.VIC]: this.team.lineup[TeamPosition.VIC],
			[TeamPosition.WA]: this.team.lineup[TeamPosition.WA],
			[TeamPosition.SA]: this.team.lineup[TeamPosition.SA],
			[TeamPosition.TAS]: this.team.lineup[TeamPosition.TAS],
		};
	}

	get userTrades(): IUserTrade[] {
		return this._userTrades || [];
	}

	get teamFullTrades(): IFullTrade[] {
		return this._userTrades.filter((trade) => {
			return trade.tradeOut && trade.tradeIn;
		}) as IFullTrade[];
	}

	get isHeadsSet(): boolean {
		return Boolean(this.team.lineup.captain) && Boolean(this.team.lineup.viceCaptain);
	}

	get isTeamNotSaved(): boolean {
		return this._hasTeamBeenChanged;
	}

	get getDriversInTeam(): number[] {
		const driversIds = Object.values(this.teamLineUp).flatMap((e) => e);
		const reserveIds = Object.values(this.team.lineup.reserve);
		return driversIds.concat(reserveIds);
	}

	get isTradingState(): boolean {
		const round = this._roundStore.list.find((e) => e.id === this.team.startRoundId);
		if (!round) {
			return false;
		}
		return round.status === RoundStatus.Complete;
	}

	// Check is team started playing
	public get isTeamScoring(): boolean {
		const isPreseason = this._roundStore.isPreseason;
		const currentRound = this._roundStore.currentRound;
		const {startRoundId, isComplete} = this.team;

		// is preseason or no round or team is not completed it can't has scores
		if (isPreseason || !currentRound || !isComplete || !startRoundId) {
			return false;
		}

		const {id, status} = currentRound;

		// if team started in the past round
		if (status === RoundStatus.Scheduled) {
			return startRoundId < id;
		}

		// round isn't scheduled and team started this round or prev round
		return startRoundId <= id;
	}

	public get isTeamInLockout(): boolean {
		return this._roundStore.isLockout && this.isTeamScoring;
	}

	get leadersIdDoubleScore() {
		const round = this._roundStore.selectedRound;
		// Get Leader Driver Object
		const capDriver = this._driversStore.getDriverById(this._team.lineup.captain);
		const viceDriver = this._driversStore.getDriverById(this._team.lineup.viceCaptain);
		if (!capDriver || !viceDriver) {
			return null;
		}

		// Check leaders score
		const capScore = this._driversStore.getPoints({driver: capDriver, round});
		const viceScore = this._driversStore.getPoints({driver: viceDriver, round});

		// Check if we have captain and he is scored
		const isCapScored = this.isScored(capScore);
		const isViceScored = this.isScored(viceScore);
		const viceIdOrNull = isViceScored ? this._team.lineup.viceCaptain : null;

		// Return Id of double scored leader
		return isCapScored ? this._team.lineup.captain : viceIdOrNull;
	}

	get isTradesAvailable() {
		const tradesOnTheRow = this.userTrades.length;
		return this.checkTradesBySide(tradesOnTheRow);
	}

	get isTradesRound() {
		return this._roundStore.isTradesRound && this.isTeamScoring;
	}

	get tradesIds() {
		return chain(this.userTrades)
			.map((trade) => [trade.tradeIn?.id, trade.tradeOut?.id])
			.flatten()
			.filter(identity)
			.value();
	}

	get madeTradeDrivers(): IFullTrade[] {
		return this.team.trades
			.map((trade) => {
				return {
					tradeIn: this._driversStore.getDriverById(trade.driverIn) || null,
					tradeOut: this._driversStore.getDriverById(trade.driverOut) || null,
				};
			})
			.filter((trade) => {
				return trade.tradeIn && trade.tradeOut;
			}) as IFullTrade[];
	}

	get tradesLeft() {
		return this.team.availableTrades - this.teamFullTrades.length;
	}

	get totalTrades() {
		return this.team.totalTrades;
	}

	get isSwapInProgress(): boolean {
		return !isNull(this.swap);
	}

	get isOverBudget() {
		return this.salaryCap < 0;
	}

	public setTeamName(name: string): void {
		if (!name) {
			return;
		}

		this._team.name = name;
	}

	@action
	public async fetchRegions() {
		if (this._isLoading) return;

		try {
			this._isLoading = true;
			const {data} = await this._teamProvider.fetchRegions();

			runInAction(() => {
				this._regions = data;
			});
		} catch (e) {
			this.handleJSONError("Error while loading regions");
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public getRegionGroupById(id: number): string | undefined {
		return this._regions.find((region) => region.id === id)?.regionGroup;
	}

	public getRegionNameById(id: number): string | undefined {
		return this._regions.find((region) => region.id === id)?.name;
	}

	@action addDriverToTeam(driverId: number): boolean {
		try {
			const driver = this._driversStore.getDriverById(driverId);

			if (!this.checkSalaryCapWithNewDriver(driver)) {
				throw new Error("Salary cap maximum exceed or driver not found");
			}

			const regionId = driver!.regionId;
			const reserve = this.team.lineup.reserve[regionId];
			const teamRow = this.team.lineup[regionId];
			const indexToReplace = teamRow?.findIndex((item) => !item);

			if (indexToReplace === -1 && Boolean(reserve)) {
				throw new Error("No empty position for driver!");
			}

			if (indexToReplace === -1 && !reserve) {
				this.cleanUpPrevoiusReservedDriver(regionId);
				this.team.lineup.reserve[regionId] = driverId!;
				this.updateSalaryCapWithNewDriver(driver!, SalaryCapAction.Add);
				this._hasTeamBeenChanged = true;
				this._reservedId = regionId;
				return true;
			}

			teamRow[indexToReplace] = driverId!;
			this.updateSalaryCapWithNewDriver(driver!, SalaryCapAction.Add);
			this._hasTeamBeenChanged = true;

			return true;
		} catch (e: unknown) {
			this.handleError(e);
			return false;
		}
	}
	private cleanUpPrevoiusReservedDriver(regionId: number) {
		if (regionId > 3) {
			this.team.lineup.reserve[4] = 0;
			this.team.lineup.reserve[5] = 0;
			this.team.lineup.reserve[6] = 0;
		}
	}
	@action removeDriverFromTeam(driverId: number | null): boolean {
		try {
			const driver = this._driversStore.getDriverById(driverId);
			if (!driverId || !driver) {
				throw new Error("Driver not found!");
			}

			const regionId = driver.regionId;
			const reserve = this._team.lineup.reserve[regionId];
			const teamRow = this._team.lineup[regionId];
			const indexToReplace = teamRow?.findIndex((item) => item === driverId);

			if (reserve === driverId) {
				this._team.lineup.reserve[regionId] = 0;
				this.updateSalaryCapWithNewDriver(driver, SalaryCapAction.Subtract);
				this.checkIsHeadsAndRemove(driverId);
				this._hasTeamBeenChanged = true;
				return true;
			}

			if (indexToReplace === -1) {
				const success = this.dropDriverFromLineup(driverId);
				this.updateSalaryCapWithNewDriver(driver, SalaryCapAction.Subtract);
				return success;
			}

			this._team.lineup[regionId][indexToReplace] = 0;
			this.updateSalaryCapWithNewDriver(driver, SalaryCapAction.Subtract);
			this.checkIsHeadsAndRemove(driverId);
			this._hasTeamBeenChanged = true;
			return true;
		} catch (e: unknown) {
			this.handleError(e);
			return false;
		}
	}

	public getIsDriverInTeam(driverId: number | null): boolean {
		if (!driverId) {
			return false;
		}
		const players = this.getDriversInTeam;
		return players.includes(driverId);
	}

	public getLineupForRow(row: TeamPosition): number[] {
		const reserve = this.team.lineup.reserve[row];
		const drivers = this.team.lineup[row];
		return [...drivers, reserve];
	}

	@action
	public toggleDriverPool(open: boolean): void {
		this._isPoolOpen = open;

		// if (open) {
		// 	document?.body?.classList.add("no-scroll");
		// } else {
		// 	document?.body?.classList.remove("no-scroll");
		// }
	}

	@action
	public clearTeam(): void {
		this._team = {
			...this.team,
			lineup: {
				[TeamPosition.QLD]: [0, 0, 0, 0],
				[TeamPosition.NSW]: [0, 0, 0, 0],
				[TeamPosition.VIC]: [0, 0, 0, 0],
				[TeamPosition.WA]: [0, 0],
				[TeamPosition.SA]: [0, 0],
				[TeamPosition.TAS]: [0, 0],
				reserve: {
					[TeamPosition.QLD]: 0,
					[TeamPosition.NSW]: 0,
					[TeamPosition.VIC]: 0,
					[TeamPosition.WA]: 0,
					[TeamPosition.SA]: 0,
					[TeamPosition.TAS]: 0,
				},
				captain: 0,
				viceCaptain: 0,
			},
		};
		this._salaryCap = this.team.salaryCap;
		this._team.value = 0;
		this._initialTeamValue = 0;
	}

	@action
	public async fetchTeam(roundId?: number) {
		if (this._isTeamLoading) return;

		try {
			this._isTeamLoading = true;
			const {data} = await this._teamProvider.fetchTeam(roundId);

			runInAction(() => {
				const team_Sample = data.success.team;
				this._team = team_Sample;
				this._initialTeamValue = data.success.team.value;
				this._salaryCap = data.success.team.salaryCap;
				this._salaryCap = data.success.team.salaryCap;
			});
		} catch (e) {
			this.handleError(e);
		} finally {
			runInAction(() => {
				this._isTeamLoading = false;
			});
		}
	}

	public async autoPick() {
		if (this._isTeamLoading) return;

		try {
			this._isTeamLoading = true;
			const {data} = await this._teamProvider.autoPick(this._team.lineup);

			runInAction(() => {
				this._team = data.success.team;
				this._initialTeamValue = data.success.team.value;
			});
			return Promise.resolve();
		} catch (e) {
			this.handleError(e);
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isTeamLoading = false;
				this._hasTeamBeenChanged = false;
			});
		}
	}

	public async saveTeam() {
		if (this._isTeamLoading) return;

		try {
			this._isTeamLoading = true;
			const {data} = await this._teamProvider.updateTeam(this.team.lineup);

			runInAction(() => {
				this._team = data.success.team;
				this._initialTeamValue = data.success.team.value;
			});
			return Promise.resolve();
		} catch (e) {
			this.handleError(e);
			return Promise.reject(e);
		} finally {
			this._isTeamLoading = false;
			this._hasTeamBeenChanged = false;
		}
	}

	public setAsCaptain(driverId?: number): void {
		if (!driverId) {
			return;
		}
		this.checkViseVersaHeadingChange(driverId, HeadsPosition.Captain);
	}

	public setAsViceCaptain(driverId: number): void {
		if (!driverId) {
			return;
		}
		this.checkViseVersaHeadingChange(driverId, HeadsPosition.ViceCaptain);
	}

	getIsDriverRole(driverId?: number, role?: "captain" | "viceCaptain"): boolean {
		if (!driverId || !role) {
			return false;
		}

		return this._team.lineup[role] === driverId;
	}

	getIsHeadsAndCanBeToggle(driverId?: number): boolean {
		if (!driverId) {
			return false;
		}
		const isCaptain = this.team.lineup.captain === driverId;
		const isViceCaptain = this.team.lineup.viceCaptain === driverId;
		const isHeadsFull =
			Boolean(this.team.lineup.captain) && Boolean(this.team.lineup.viceCaptain);

		return (isHeadsFull && isCaptain) || (isHeadsFull && isViceCaptain);
	}

	getIsDriverInReserve(driverId?: number): boolean {
		if (!driverId) {
			return false;
		}
		return Object.values(this.team.lineup.reserve).some((e) => e === driverId);
	}

	@action
	removeRole(role: HeadsPosition): void {
		this._team.lineup[role] = 0;
		runInAction(() => {
			this._hasTeamBeenChanged = true;
		});
	}

	public fetchTeamForSelectedRound() {
		const selectedRound = this._roundStore.selectedRound;
		if (!selectedRound) {
			return;
		}

		// If round playing - remove query as it's last round
		if (selectedRound.status === RoundStatus.Playing) {
			void this.fetchTeam();
			return;
		}

		void this.fetchTeam(selectedRound.id);
	}

	hasGroupDrivers(round: IRound) {
		const {races} = round;
		const ids = this.getDriversInTeam;

		return (
			races.filter((race) => {
				const isTeamDriverInRace = intersection(race.drivers, ids).length > 0;
				const isGroupRace = [race.group1, race.group2].includes(true);
				return isTeamDriverInRace && isGroupRace;
			}).length > 0
		);
	}

	isScored(score: number | string) {
		return score !== 0 && score !== "DNP";
	}

	@action
	isOutTradesAvailable(driver: IDriver) {
		return this.isOppositeTradeSideHasSameRegion(driver.regionId, TradeSideType.tradeOut);
	}

	@action
	isInTradesAvailable(driver: IDriver) {
		return this.isOppositeTradeSideHasSameRegion(driver.regionId, TradeSideType.tradeIn);
	}

	isOppositeTradeSideHasSameRegion(regionId: number, side: TradeSideType) {
		if (!this.isTradesRound) {
			return false;
		}
		const tradesOnTheRow = this.userTrades.filter((trade) => trade[side]);
		if (!this.isTradesLeft(tradesOnTheRow.length)) {
			return false;
		}
		if (this.isTradesAvailable) {
			return true;
		}
		const isTradeIn = side === TradeSideType.tradeIn;
		const oppositeSide = isTradeIn ? TradeSideType.tradeOut : TradeSideType.tradeIn;
		const oppositeTrades = this.userTrades.filter((trade) => trade[oppositeSide]);

		return (
			oppositeTrades.filter((trade) => {
				return !trade[side] && trade[oppositeSide]?.regionId === regionId;
			}).length > 0
		);
	}

	checkTradesBySide(sideLength: number) {
		return this.isTradesRound && this.isTradesLeft(sideLength);
	}

	isTradesLeft(sideLength: number) {
		return this.team.availableTrades - sideLength > 0;
	}

	@action
	tryAddDriverToTrade(driver: IDriver, side: TradeSideType) {
		const trades = toJS(this.userTrades);
		const isTradeIn = side === TradeSideType.tradeIn;
		const oppositeSide = isTradeIn ? TradeSideType.tradeOut : TradeSideType.tradeIn;
		const salarySign = isTradeIn ? SalaryCapAction.Add : SalaryCapAction.Subtract;
		if (!trades.length) {
			this._userTrades = [
				{
					[side]: driver,
					[oppositeSide]: null,
				} as IUserTrade,
			];
			this.updateSalaryCapWithNewDriver(driver, salarySign);
			return;
		}
		// Find same region id (position) Or add new trade row
		let hasPair = false;
		this._userTrades = trades.map((trade) => {
			// If same region and side is empty
			if (!hasPair && trade[oppositeSide]?.regionId === driver.regionId && !trade[side]) {
				hasPair = true;
				return {
					...trade,
					[side]: driver,
				};
			}
			return trade;
		});
		if (!hasPair) {
			this._userTrades = [
				...trades,
				{
					[side]: driver,
					[oppositeSide]: null,
				} as IUserTrade,
			];
		}

		this.updateSalaryCapWithNewDriver(driver, salarySign);
	}

	@action
	removeDriverFromTrade(id: number, side: TradeSideType) {
		const isTradeIn = side === TradeSideType.tradeIn;
		const salarySign = isTradeIn ? SalaryCapAction.Subtract : SalaryCapAction.Add;
		const updatedTrades = toJS(this.userTrades).map((trade: IUserTrade) => {
			if (trade[side]?.id === id) {
				return {
					...trade,
					[side]: null,
				};
			}
			return trade;
		});
		this._userTrades = updatedTrades.filter(
			(trade) => trade[TradeSideType.tradeOut] || trade[TradeSideType.tradeIn]
		);
		const driver = this._driversStore.getDriverById(id);
		if (driver) {
			this.updateSalaryCapWithNewDriver(driver, salarySign);
		}
	}

	isDriverOnTrade(id?: number) {
		if (!id) {
			return false;
		}

		return this.tradesIds.includes(id);
	}

	@action
	resetTrades() {
		if (this._userTrades.length > 0) {
			this._userTrades = [];
			this._team.value = this._initialTeamValue;
		} else {
			this._modalStore.showModal(ModalType.TRADE_RESET);
		}
	}

	@action
	public async makeTrades() {
		if (this._isTeamLoading) return;

		try {
			this._isTeamLoading = true;
			const trades = this.teamFullTrades.map((trade) => {
				return {
					driverOut: trade.tradeOut.id,
					driverIn: trade.tradeIn.id,
				};
			});

			const {data} = await this._teamProvider.makeTrades(trades);

			runInAction(() => {
				this._userTrades = [];
				this._team = data.success.team;
				this._initialTeamValue = data.success.team.value;
			});
		} catch (e) {
			this.handleError(e);
		} finally {
			this._isTeamLoading = false;
			this._hasTeamBeenChanged = false;
		}
	}

	@action
	async resetMadeTrades() {
		if (this._isTeamLoading) return;

		try {
			this._isTeamLoading = true;

			const {data} = await this._teamProvider.rollbackTrades();

			runInAction(() => {
				this._team = data.success.team;
				this._initialTeamValue = data.success.team.value;
			});
		} catch (e) {
			this.handleError(e);
		} finally {
			this._isTeamLoading = false;
			this._hasTeamBeenChanged = false;
		}
	}

	resetTrade(index: number) {
		const {tradeOut, tradeIn} = this._userTrades[index];
		if (tradeOut) {
			this.updateSalaryCapWithNewDriver(tradeOut, SalaryCapAction.Add);
		}
		if (tradeIn) {
			this.updateSalaryCapWithNewDriver(tradeIn, SalaryCapAction.Subtract);
		}
		this._userTrades.splice(index, 1);
	}

	addToSwap(driver: IDriver) {
		this.swap = this.swap?.id === driver.id ? null : driver;
	}

	isOnSwap(driver: IDriver): boolean {
		return this.swap?.id === driver.id;
	}

	canBeSwapped(driver: IDriver): boolean {
		return this.swap?.regionId === driver.regionId;
	}

	async swapDriver(driver: IDriver) {
		if (!this.swap) {
			return this.addToSwap(driver);
		}
		const lineup = toJS(this.team.lineup);

		const isReserve1 = lineup.reserve[this.swap.regionId] === this.swap.id;
		const teamRow1 = lineup[this.swap.regionId];
		const indexToReplace1 = teamRow1?.findIndex((driverId) => driverId === this.swap!.id);

		const isReserve2 = lineup.reserve[driver.regionId] === driver.id;
		const teamRow2 = lineup[driver.regionId];
		const indexToReplace2 = teamRow2?.findIndex((driverId) => driverId === driver.id);

		if (isReserve1) {
			lineup.reserve[this.swap.regionId] = driver.id;
		} else {
			lineup[this.swap.regionId][indexToReplace1] = driver.id;
		}

		if (isReserve2) {
			lineup.reserve[driver.regionId] = this.swap.id;
		} else {
			lineup[driver.regionId][indexToReplace2] = this.swap.id;
		}

		this._team.lineup = lineup;

		this.swap = null;
		await this.saveTeam();
	}

	private checkSalaryCapWithNewDriver(driver: IDriver | undefined): boolean {
		if (!driver) {
			return false;
		}
		return this._salaryCap - driver.cost > 0;
	}

	@action
	private updateSalaryCapWithNewDriver(driver: IDriver, action: SalaryCapAction): void {
		if (action === SalaryCapAction.Subtract) {
			this._team.value -= driver.cost;
			return;
		}

		this._team.value += driver.cost;
	}

	private handleError(e: unknown) {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalStore.showAxiosError(error);
	}

	private handleJSONError(message: string) {
		const error = {
			title: "Error",
			message,
		};
		this._modalStore.showError(error);
	}

	@action
	private checkIsHeadsAndRemove(driverId: number): void {
		if (this._team.lineup.captain === driverId) {
			this.removeRole(HeadsPosition.Captain);
			runInAction(() => {
				this._hasTeamBeenChanged = true;
			});
			return;
		}

		if (this._team.lineup.viceCaptain === driverId) {
			this.removeRole(HeadsPosition.ViceCaptain);
			runInAction(() => {
				this._hasTeamBeenChanged = true;
			});
		}
	}

	private checkViseVersaHeadingChange(driverId?: number, role?: HeadsPosition) {
		if (!driverId || !role) {
			return;
		}

		// Switch ViceCaptain <--> Captain if set ViceCaptain as Captain
		if (role === HeadsPosition.Captain && this.team.lineup.viceCaptain === driverId) {
			const temp = this.team.lineup.captain;
			this._team.lineup.captain = driverId;
			this.team.lineup.viceCaptain = temp;
			runInAction(() => {
				this._hasTeamBeenChanged = true;
			});
			return;
		}

		// Switch ViceCaptain <--> Captain if set  Captain as ViceCaptain
		if (role === HeadsPosition.ViceCaptain && this.team.lineup.captain === driverId) {
			const temp = this.team.lineup.viceCaptain;
			this._team.lineup.viceCaptain = driverId;
			this.team.lineup.captain = temp;
			runInAction(() => {
				this._hasTeamBeenChanged = true;
			});
			return;
		}

		// Set role if not necessary to swipe roles
		runInAction(() => {
			this._hasTeamBeenChanged = true;
		});
		this._team.lineup[role] = driverId;
	}

	@action
	private dropDriverFromLineup(driverId: number): boolean {
		const {captain, viceCaptain, reserve, ...rest} = this.team.lineup;
		Object.keys(rest).forEach((region) => {
			const regionNumber = Number(region) as TeamPosition;
			this.team.lineup[regionNumber].forEach((id, index) => {
				if (id === driverId) {
					runInAction(() => {
						this._team.lineup[regionNumber][index] = 0;
					});
					return true;
				}
			});
		});

		Object.keys(reserve).forEach((key, index) => {
			const numberKey = Number(key);
			if (reserve[numberKey] === driverId) {
				runInAction(() => {
					this._team.lineup.reserve[numberKey] = 0;
					return true;
				});
			}
		});
		this.checkIsHeadsAndRemove(driverId);
		return false;
	}
}
