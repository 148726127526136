import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IState {
	code: string;
	name: string;
}

export interface IAUState {
	AU: IState[];
}
export interface IStatesStore {
	get list(): IState[];

	fetchStates(): Promise<void>;
}

@injectable()
export class StatesStore implements IStatesStore {
	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IState[] = [];

	get list() {
		return this._list;
	}

	@action
	async fetchStates() {
		// const data = this._jsonProvider.fetchStates();
		try {
			const {data} = await this._jsonProvider.fetchStates();
			if (data) {
				runInAction(() => {
					this._list = data.AU;
				});
			}
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		}
	}
}
