import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {get} from "lodash";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	ILadderUser,
	ILeague,
	ILeagueApiProvider,
} from "data/providers/api/league.api.provider";
import {H2HFinalType, LeaguePrivacy, LeagueType, RoundStatus} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {AxiosError} from "axios";
import {Empty, IAxiosApiError} from "data/types/global";

export interface IFetchLeaguesParams {
	page?: number;
	search?: string;
}

export interface IFetchLeagueRanksPayload {
	league_id: number;
	page: number;
	round?: number;
}

export interface IFetchLeagueParams {
	leagueId: number;
}

export interface IJoinLeaguesParams {
	code: string;
}

export interface ILeaveLeagueParams {
	leagueId: number;
}

export interface ICreateLeagueParams {
	privacy: LeaguePrivacy;
	name: string;
	startRound?: number | null;
	type: LeagueType;
	teamLimit?: number;
	finalsFormat?: H2HFinalType;
}

export interface IUpdateLeague {
	name: string;
	teamLimit?: number | null;
	startRound: number;
	finalsFormat?: H2HFinalType;
}

export interface IInviteLeagueParams {
	league_id: number;
	emails: {
		email: string;
	}[];
}

export interface IShowLadderUsers {
	league_id: number;
	page?: number;
	round?: number;
}

export interface IJoinedUser {
	userId: number;
	firstName: string;
	lastName: string;
	userName?: string;
}

export interface IRemoveUserModalPayload {
	userid: number;
	username: string;
}

export interface IShowJoinedUsersParams {
	league_id: number;
	page: number;
}

export interface ILeaguesStore {
	list: ILeague[];
	nextPage: boolean;
	myLeagues: ILeague[];
	fetchLeagues: (params: IFetchLeaguesParams) => Promise<void>;
	fetchMoreLeagues: (params: IFetchLeaguesParams) => Promise<void>;
	fetchMyLeagues: () => Promise<void>;
	fetchMoreMyLeagues: (params: IFetchLeaguesParams) => Promise<void>;
	createLeague: (params: ICreateLeagueParams) => Promise<ILeague | undefined>;
	selectedLeague: ILeague | undefined;
	inviteLeague: (params: IInviteLeagueParams) => Promise<boolean>;
	fetchLadder: (round: number, firstPage?: boolean) => void;
	loadMoreLadder: (round: number) => void;
	updateLeague: (params: IUpdateLeague, leagueId: number) => Promise<void>;
	fetchJoinedUsers: () => void;
	removeUser: (id: number) => void;
	clearUsers: () => void;
	setSelectedLeague: (id: number) => void;
	getLeague: (params: IFetchLeagueParams) => Promise<void | undefined>;
	getIsH2HLeagueStarted: (league: Empty<ILeague>) => boolean;
	getIsLeagueStarted: (league: Empty<ILeague>) => boolean;

	get ladderUsers(): ILadderUser[];

	get isRequestInProgress(): boolean;

	get joinedUsers(): IJoinedUser[];

	get rankingsUser(): null | ILadderUser;

	getLeagueById(leagueId: number): ILeague | undefined;

	leaveLeague(params: ILeaveLeagueParams): Promise<ILeague[] | void>;

	deleteLeague(params: ILeaveLeagueParams): Promise<unknown>;

	joinLeague(code: string): Promise<void>;

	get isSingleLeagueLoading(): boolean;
}

@injectable()
export class LeaguesStore implements ILeaguesStore {
	@observable private _page: number = 1;
	@observable private _fetching: boolean = false;
	@observable private _isFetchUsersLoading = false;
	@observable private _isSingleLeagueLoading = false;

	constructor(
		@inject(Bindings.LeagueApiProvider) private _leagueApiProvider: ILeagueApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isSingleLeagueLoading(): boolean {
		return this._isSingleLeagueLoading;
	}

	@observable private _rankingsUser: ILadderUser | null = null;

	get rankingsUser(): ILadderUser | null {
		return this._rankingsUser;
	}

	@observable private _list: ILeague[] = [];

	get list() {
		return this._list;
	}

	@observable private _myLeagues: ILeague[] = [];

	get myLeagues() {
		return this._myLeagues;
	}

	@observable private _nextPage: boolean = false;

	get nextPage() {
		return this._nextPage;
	}

	@observable private _selectedLeague: ILeague | undefined;

	get selectedLeague() {
		return this._selectedLeague;
	}

	@observable private _ladderUsers: ILadderUser[] = [];

	get ladderUsers() {
		return this._ladderUsers;
	}

	@observable private _joinedUsers: IJoinedUser[] = [];

	get joinedUsers() {
		return this._joinedUsers;
	}

	get isRequestInProgress() {
		return this._fetching;
	}

	getLeagueById(leagueId: number): ILeague | undefined {
		return this.myLeagues.find((league) => {
			return league?.id === leagueId;
		});
	}

	@action setSelectedLeague(id: number) {
		const selectedLeague = this.myLeagues.find((league) => league.id === Number(id));
		runInAction(() => {
			this._selectedLeague = selectedLeague;
		});
	}

	@action
	async fetchLeagues(params: IFetchLeaguesParams) {
		try {
			const response = await this._leagueApiProvider.showLeagues(params);
			runInAction(() => {
				this._list = response.data.success.leagues;
				this._nextPage = response.data.success.nextPage;
			});
		} catch (e) {
			this.handleError(e);
		}
	}

	@action
	async fetchMoreLeagues(params: IFetchLeaguesParams) {
		try {
			const response = await this._leagueApiProvider.showLeagues(params);

			runInAction(() => {
				this._list = this._list.concat(response.data.success.leagues);
				this._nextPage = response.data.success.nextPage;
			});
		} catch (e) {
			this.handleError(e);
		}
	}

	@action
	async createLeague(params: ICreateLeagueParams) {
		const response = await this._leagueApiProvider.createLeague(params);
		const league = response.data.success?.league;
		runInAction(() => {
			this._list = this._list.concat(league);
			this._selectedLeague = league;
		});
		return league || undefined;
	}

	@action
	async updateLeague(params: IUpdateLeague, leagueId: number) {
		try {
			const response = await this._leagueApiProvider.updateLeague(params, leagueId);
			const league = response.data.success?.league;
			runInAction(() => {
				this._list = this._list.concat(league);
				this._selectedLeague = league;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	async fetchJoinedUsers() {
		if (this._isFetchUsersLoading || !this.selectedLeague?.id) {
			return;
		}

		try {
			this._isFetchUsersLoading = true;
			const payload = {
				league_id: this.selectedLeague.id,
				page: this._page,
			};
			const response = await this._leagueApiProvider.showJoinedUsers(payload);
			runInAction(() => {
				this._nextPage = response.data.success.nextPage;
				this._joinedUsers = [...this._joinedUsers, ...response.data.success.users];
				this._page = this._page + 1;
				this._fetching = false;
			});
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		} finally {
			this._isFetchUsersLoading = false;
		}
	}

	@action
	async removeUser(id: number) {
		try {
			const payload = {
				user_id: id,
				league_id: this.selectedLeague?.id || 0,
			};
			await this._leagueApiProvider.removeUser(payload);
			this._joinedUsers = this._joinedUsers.filter((user) => user.userId !== id);
			this._fetching = false;
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		}
	}

	@action
	clearUsers(): void {
		runInAction(() => {
			this._joinedUsers = [];
			this._nextPage = false;
		});
	}

	@action
	async fetchMyLeagues() {
		const response = await this._leagueApiProvider.showMyLeagues();

		runInAction(() => {
			this._myLeagues = response.data.success.leagues;
			this._nextPage = response.data.success.nextPage;
		});
	}

	@action
	async fetchMoreMyLeagues(params: IFetchLeaguesParams) {
		try {
			const response = await this._leagueApiProvider.showMyLeagues(params);

			runInAction(() => {
				this._myLeagues = this._myLeagues.concat(response.data.success.leagues);
				this._nextPage = response.data.success.nextPage;
			});
		} catch (e) {
			this.handleError(e);
		}
	}

	@action
	async fetchLadder(round: number, firstPage = true) {
		if (!this.selectedLeague || this._fetching) {
			return;
		}

		// Clear all if load ladders from 1st page (when first load or round/overall select change)
		if (firstPage) {
			this._page = 1;
			this._ladderUsers = [];
			this._rankingsUser = null;
		}
		this._fetching = true;

		const payload: IFetchLeagueRanksPayload = {
			league_id: this.selectedLeague.id,
			page: this._page,
			round: round === 0 ? undefined : round,
		};

		const response = await this._leagueApiProvider.showLadderUsers(payload);

		runInAction(() => {
			this._ladderUsers = [...this._ladderUsers, ...response.data.success.rankings];
			this._nextPage = response.data.success.nextPage;
			this._rankingsUser = response.data.success.user;
			this._page = this._page + 1;
			this._fetching = false;
		});
	}

	@action
	async loadMoreLadder(round: number) {
		await this.fetchLadder(round, false);
	}

	@action getLeague(params: IFetchLeagueParams) {
		// if (this._isSingleLeagueLoading) {
		// 	return Promise.reject("League request in process");
		// }

		this._isSingleLeagueLoading = true;
		return this._leagueApiProvider
			.getLeague(params)
			.then((response) => {
				const league = get(response, "data.success");
				runInAction(() => {
					this._selectedLeague = league;
				});
			})
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				runInAction(() => {
					this._isSingleLeagueLoading = false;
				});
			});
	}

	@action
	async inviteLeague(params: IInviteLeagueParams): Promise<boolean> {
		const {data} = await this._leagueApiProvider.inviteLeague(params);
		return get(data, "errors").length === 0;
	}

	@action
	async leaveLeague(params: ILeaveLeagueParams): Promise<ILeague[]> {
		try {
			await this._leagueApiProvider.leaveLeague(params);

			runInAction(() => {
				this.toggleSelectedLeagueJoined(false);
				this._myLeagues = this._myLeagues.filter((league) => league.id !== params.leagueId);
			});
		} catch (e) {
			this.handleError(e);
			return Promise.reject(e);
		}
		return this._myLeagues;
	}

	@action
	async deleteLeague(params: ILeaveLeagueParams): Promise<unknown> {
		try {
			await this._leagueApiProvider.deleteLeague(params);
			runInAction(() => {
				this._myLeagues = this._myLeagues.filter((league) => league.id !== params.leagueId);
			});
		} catch (e) {
			this.handleError(e);
			return Promise.reject(e);
		}
	}

	@action
	async joinLeague(code: string): Promise<void> {
		try {
			const response = await this._leagueApiProvider.joinLeagues({code});
			this.toggleSelectedLeagueJoined(true);
			runInAction(() => {
				this._list = this._list.map((league) => {
					return response.data.success.league.code === league.code
						? {
								...league,
								isJoined: true,
						  }
						: league;
				});
			});
		} catch (e) {
			this.handleError(e);
			return Promise.reject(e);
		}
	}

	getIsH2HLeagueStarted(league: Empty<ILeague>): boolean {
		if (!league || league?.type !== LeagueType.H2H) {
			return false;
		}

		return this.getIsLeagueStarted(league) && this.getIsLeagueFullFilled(league);
	}

	getIsLeagueStarted(league: Empty<ILeague>): boolean {
		const round = this._roundStore.getRoundById(get(league, "startRound"));
		return Boolean(round) && round?.status !== RoundStatus.Scheduled;
	}

	private getIsLeagueFullFilled(league: Empty<ILeague>): boolean {
		if (!league?.teamLimit) {
			return false;
		}
		return league?.teamCount === league.teamLimit;
	}

	private handleError(e: unknown) {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	}

	private toggleSelectedLeagueJoined(isJoined: boolean) {
		if (!this._selectedLeague) {
			return;
		}

		runInAction(() => {
			this._selectedLeague!.isJoined = isJoined;
		});
	}
}
