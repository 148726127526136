import {createTheme} from "@mui/material";

const primaryColor = "#005055";
const themeObject = {
	colors: {
		primary: "#005055",
		black: "#151515",
		white: "#FFFFFF",
		actionDanger: "#c20000",
	},
	typography: {
		fontFamily: "var(--fontFamilyBase), sans serif",
		h2: {
			fontSize: "28px",
		},
		h3: {
			fontSize: "24px",
		},
		h4: {
			fontSize: "20px",
		},
	},

	palette: {
		primary: {
			main: primaryColor,
		},
		grey: {
			"50": "#F2F2F5",
			"100": "#E5E5EA",
			"200": "#C7C7CC",
			"300": "#AEAEB2",
			"400": "#8E8E93",
			"500": "#636366",
			"600": "#48484A",
			"700": "#3A3A3C",
			"800": "#2C2C2E",
			"900": "#1C1C1E",
			A100: "#f5f5f5",
			A200: "#eeeeee",
			A400: "#bdbdbd",
			A700: "#616161",
		},
		background: {
			default: "#FFFFFF",
			paper: "#FFFFFF",
		},
		error: {
			contrastText: "#fff",
			dark: "rgb(170, 46, 37)",
			light: "rgb(246, 104, 94)",
			main: "#f44336",
		},
		success: {
			contrastText: "rgba(0, 0, 0, 0.87)",
			dark: "#108c4f",
			light: "#46d38d",
			main: "#139F5A",
		},
		text: {
			disabled: "rgba(0, 0, 0, 0.38)",
			primary: "#101010",
			secondary: "#8E8E93",
		},
	},
	components: {
		MuiChip: {
			styleOverrides: {
				root: {
					fontSize: "11px",
					fontFamily: "var(--fontFamilyBase)",
					color: "#AEAEB2",
					fontWeight: 500,
				},
				colorPrimary: {
					backgroundColor: "rgba(33, 150, 243, .1)",
					color: "#2196F3",
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: "none",
					borderRadius: "32px",
					":disabled": {
						color: "#fff",
						background: "#00689F",
						opacity: 0.3,
					},
				},
				contained: {
					textTransform: "uppercase",
					color: "#fff",
				},
				outlined: {
					textTransform: "uppercase",
					color: "#000",
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				colorError: {
					color: "#F44336",
				},

				colorAction: {
					color: "#000",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					background: "#FFFFFF",
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
				flexContainer: {
					borderBottom: "2px solid gray",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					background: "#FFF",
					color: primaryColor,
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					background: primaryColor,
					color: "var(--textColor4)",
				},
			},
		},

		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.own-user": {
						background: "#931A20",
						"&: hover": {
							background: "#500e11",
						},
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					padding: "5px 16px 5px 20px",
					".own-user &": {
						color: "#fff",
						"&: hover": {
							color: "#fff",
						},
					},
				},
			},
		},
		MuiSnackbar: {
			defaultProps: {
				anchorOrigin: {
					horizontal: "right",
					vertical: "bottom",
				},
				autoHideDuration: 5000,
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					background: "#fff",
					color: "#000",
				},
			},
		},
	},
	field: {
		bar: {
			background: "#FFFFFF",
			color: "#00689F",
		},
		legend: {
			background: "#FFFFFF",
			color: "#151515",
		},
	},

	driverProfile: {
		heading: {
			color: "#FFFFFF",
		},
		stats: {
			background: "#F2F2F5",
			block: {
				background: "#FFFFFF",
				mainColor: "#00689F",
				secondaryColor: "#9A9996",
			},
		},
		actions: {
			background: "#FFFFFF",
			captain: "#1FB4DA",
			viceCaptain: "#333333",
			remove: "#C20000",
			transfer: "#333333",
			disabled: "#8E8E93",
			disabledFill: "#8E8E93",
		},
	},
	button: {
		primary: {
			default: {
				background: "#005055",
				color: "#FFFFFF",
			},
			hover: {
				background: "var(--platform-primary-hover)",
				color: "#FFFFFF",
			},
			disabled: {
				background: "#8E8E93",
				color: "#FFFFFF",
				opacity: "0.3",
			},
		},
		secondary: {
			default: {
				background: "transparent",
				color: "#00689F",
			},
			hover: {
				background: "transparent",
				color: "rgb(0,104,159, 0.8)",
			},
			disabled: {
				background: "#00689F",
				color: "#00689F",
				opacity: "0.3",
			},
		},
	},
};

export const breakpoints = {
	fieldMobile: "1000px",
	mobile: "768px",
};

type ITheme = typeof themeObject;
// TODO: Refactor if will have time | Disabled as for now make no sense to spend a lot of time to fix it
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const theme = createTheme(themeObject);

declare module "@mui/material/styles" {
	/**
	 *Disabled as it's allowing to avoid `any` type per MUI documentation
	 * https://mui.com/material-ui/customization/theming/#custom-variables
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}

declare module "@emotion/react" {
	/**
	 *Disabled as it's allowing to avoid `any` type per Emotion documentation
	 * https://emotion.sh/docs/typescript#define-a-theme
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}
