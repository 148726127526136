import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IDriverStore} from "data/stores/driver/drivers.store";
import type {IDriver, IDriverPriceChange} from "data/types/team";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {DriverStatus, ModalType, TeamPosition, TeamStats, TradeSideType} from "data/enums";
import {action, makeAutoObservable, observable, runInAction, toJS, when} from "mobx";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {isNumber, uniq} from "lodash";
import {getDashedValue} from "data/utils";

interface IInitProps {
	driverId?: number;
	regionId?: number;
}

export interface IFieldDriverController extends ViewController<IInitProps> {
	removeDriverFromField: (driverId: number) => void;
	sortDriverPoolByRegion: (regionId: TeamPosition) => void;
	openDriverProfile: (driverId: TeamPosition) => void;
	openDriverPool: () => void;
	handleDriverOnTrade: () => void;
	swap: () => void;

	get regionName(): string;

	get isPointsStatSelected(): boolean;

	get driver(): IDriver | undefined;

	get driverCostChangeEntity(): IDriverPriceChange | undefined;

	get isLockout(): boolean;

	get isTeamScoring(): boolean;

	get roundPoints(): string | number;

	get driverGroups(): number[];

	get showTradesIcon(): boolean;

	get isOnTrade(): boolean;

	get isDriverEliminated(): boolean;

	get canBeSwapped(): boolean;

	get isSwapInProgress(): boolean;

	get isOnSwap(): boolean;

	get opacityClass(): string;
}

@injectable()
export class FieldDriverController implements IFieldDriverController {
	private _driverId: number | null = null;
	private _regionId: number | null = null;

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@observable _driver: IDriver | undefined;
	@observable _regionText: string = "";

	get regionName(): string {
		return this._regionText;
	}

	get driver(): IDriver | undefined {
		return this._driver;
	}

	get driverCostChangeEntity() {
		if (!this.driver || !this._roundsStore.currentRound) {
			return undefined;
		}
		return this._driverStore.getDriverPriceChanges(
			this.driver.id,
			this._roundsStore.currentRound?.id
		);
	}
	get isPointsStatSelected(): boolean {
		return this._teamStore.fieldStats === TeamStats.Points;
	}

	get jsDriver() {
		return toJS(this.driver);
	}

	public get isLockout() {
		return this._teamStore.isTeamInLockout;
	}

	public get isTeamScoring() {
		return this._teamStore.isTeamScoring;
	}

	dispose(): void {
		return;
	}

	init(param: IInitProps): void {
		this._driverId = param.driverId || null;
		this._regionId = param.regionId || null;
		if (param.driverId) {
			this.fetchDriver();
		}
		if (param.regionId) {
			this.fetchRegionName();
		}
	}

	removeDriverFromField = (driverId: number): void => {
		if (!driverId) {
			return;
		}
		this._teamStore.removeDriverFromTeam(driverId);
	};

	public sortDriverPoolByRegion = (regionId: TeamPosition): void => {
		this._driverStore.updatePoolRegion(regionId);
	};

	public openDriverPool = (): void => {
		this._teamStore.toggleDriverPool(true);
	};

	@action
	private fetchDriver() {
		when(
			() => this._driverStore.drivers.length > 0,
			() => {
				runInAction(() => {
					this._driver = this._driverStore.getDriverById(this._driverId);
				});
			}
		);
	}

	private fetchRegionName() {
		this._regionText = getDashedValue(this._teamStore.getRegionNameById(this._regionId || 0));
	}
	openDriverProfile = (driverId: TeamPosition) => {
		this._modalStore.showModal(ModalType.DRIVER_PROFILE, {driverId});
	};

	get roundPoints(): string | number {
		const round = this._roundsStore.selectedRound;

		if (!this.jsDriver || !round) {
			return "-";
		}
		const points = this._driverStore.getPoints({driver: this.jsDriver, round});
		// Get double scored driver id
		const leaderIdDoublePoints = this._teamStore.leadersIdDoubleScore;

		// If this is driver is double scored leader - double score
		return this._driverId === leaderIdDoublePoints && isNumber(points) ? points * 2 : points;
	}

	get driverGroups(): number[] {
		const round = this._roundsStore.selectedRound;
		const races = toJS(round)?.races;

		if (!this.jsDriver || !races) {
			return [];
		}

		const driverRaces = races.filter((race) => {
			const isDriverInRace = race.drivers.includes(this.jsDriver!.id);
			const isGroupRace = [race.group1, race.group2].includes(true);
			return isDriverInRace && isGroupRace;
		});
		return driverRaces.length > 0
			? uniq(
					driverRaces.map((race) => {
						return race.group1 ? 1 : 2;
					})
			  ).sort()
			: [];
	}

	get showTradesIcon(): boolean {
		if (!this._driver) {
			return false;
		}
		return this._teamStore.isOutTradesAvailable(this._driver);
	}

	handleDriverOnTrade = () => {
		if (!this.jsDriver) {
			return;
		}
		if (this.isOnTrade) {
			this._teamStore.removeDriverFromTrade(this.jsDriver.id, TradeSideType.tradeOut);
		} else {
			this._teamStore.tryAddDriverToTrade(this.jsDriver, TradeSideType.tradeOut);
			this._teamStore.toggleDriverPool(true);
		}
	};

	get isOnTrade(): boolean {
		return this._teamStore.isDriverOnTrade(this.jsDriver?.id);
	}

	get isDriverEliminated(): boolean {
		return this.driver?.status === DriverStatus.Eliminated;
	}

	get canBeSwapped(): boolean {
		if (!this.driver) {
			return false;
		}
		return this._teamStore.canBeSwapped(this.driver);
	}

	get isSwapInProgress(): boolean {
		return this._teamStore.isSwapInProgress;
	}

	get isOnSwap(): boolean {
		if (!this.driver) {
			return false;
		}
		return this._teamStore.isOnSwap(this.driver);
	}

	get opacityClass(): string {
		return this.isSwapInProgress && !this.canBeSwapped ? "half-opacity" : "full-opacity";
	}

	swap = () => {
		if (!this.driver) {
			return false;
		}
		return this._teamStore.swapDriver(this.driver);
	};
}
