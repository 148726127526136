import React from "react";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {NavigateFunction} from "react-router-dom";
import type {IAxiosApiError} from "data/types/global";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ICountry} from "data/providers/json/json.provider";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {IState, IStatesStore} from "data/stores/states/states.store";
import {POSTCODE_COUNTRY} from "data/constants";
import {IMyAccountFormValues} from "views/pages/my_account/my_account.controller";
import {formatPhoneNumber} from "data/utils";

interface IForm extends HTMLFormElement {
	isDriver: HTMLInputElement;
	terms: HTMLInputElement;
	phone: HTMLInputElement;
	country: HTMLInputElement;
	state: HTMLInputElement;
}

interface IParams {
	navigate: NavigateFunction;
}

export interface IModalTermsController extends ViewController<IParams> {
	handleFormChange: ({target}: React.ChangeEvent<IForm>) => void;
	handleFormSubmit: (e: React.SyntheticEvent<IForm>) => void;

	formValues: IMyAccountFormValues;
	get countryList(): ICountry[];

	get isOpen(): boolean;

	get isChecked(): boolean;

	get phoneError(): boolean;

	get isLoading(): boolean;

	get statesList(): IState[];

	get isAU(): boolean;

	get defaultCountry(): string;

	setCountry: (value: string) => void;

	setState: (value: string) => void;

	handleInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class ModalTermsController implements IModalTermsController {
	@observable private _phone: string = "";
	@observable private _phoneError: boolean = false;
	@observable private _isLoading: boolean = false;
	@observable private _isChecked: boolean = false;
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.CountriesStore) private _countryStore: ICountriesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.StatesStore) private readonly _statesStore: IStatesStore
	) {
		makeAutoObservable(this);
	}

	@observable _formValues = {
		isNotificationsEnabled: this._userStore.user?.isNotificationsEnabled,
		optIn: this._userStore.user?.optIn,
		state: this._userStore.user?.state,
		phone: formatPhoneNumber(this._userStore.user?.phone),
		country: this._userStore.user?.country || this.defaultCountry,
	};

	get formValues() {
		return this._formValues;
	}

	get isAU() {
		// If nothing selected - default value AU
		if (!this._userStore.user?.country) {
			return true;
		}
		return this._formValues?.country === POSTCODE_COUNTRY;
	}

	get defaultCountry() {
		return "AUS";
	}

	public get phoneError(): boolean {
		return this._phoneError;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get isOpen(): boolean {
		if (!this.user) {
			return false;
		}
		return !this.user.recovered;
	}

	public get isChecked(): boolean {
		return this._isChecked;
	}

	public get countryList() {
		return this._countryStore.list;
	}

	protected get user(): IUser | undefined {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		void this._countryStore.fetchCountries();
		void this._statesStore.fetchStates();
	}

	public handleFormSubmit = (e: React.SyntheticEvent<IForm>): void => {
		e.preventDefault();

		const {isDriver, country, phone, state = null} = e.currentTarget;

		const phoneValue = phone?.value.replaceAll(/[- )(]/gm, "");
		if (phone?.value.length > 0 && phoneValue?.length !== 10) {
			this._phoneError = true;
			return;
		}
		// if (this._phone.length > 0 && this._phone.length !== 10) {
		// 	this._phoneError = true;
		// 	return;
		// }

		const payload = {
			isDriver: isDriver.checked,
			phone: phoneValue ? Number(phoneValue.replace(/\D/g, "")) : 456456,
			country: country.value || undefined,
			state: state?.value || undefined,
		};

		this._isLoading = true;
		this._userStore
			.recoverUser(payload)
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				runInAction(() => {
					this.navigateTo("/my-team");
					this._isLoading = false;
				});
			});
	};

	handleInputFieldChange = ({
		target: {name, value, checked},
	}: React.ChangeEvent<HTMLInputElement>) => {
		console.log("inputhange", name, value);

		// if (name === "phone") {
		// 	this._phoneError = false;
		// 	this._phone = String(value).replaceAll(/[- )(]/gm, "");
		// 	return;
		// }
		// if (name === "terms") {
		// 	this._isChecked = checked as boolean;
		// }
	};

	public handleFormChange = ({target}: React.ChangeEvent<IForm>): void => {
		if (target.name === "phone") {
			this._phoneError = false;
			this._phone = String(target.value).replaceAll(/[- )(]/gm, "");
			return;
		}
		if (target.name === "terms") {
			this._isChecked = target.checked as boolean;
		}
	};
	get statesList() {
		return this._statesStore.list;
	}

	setCountry = (value: string) => {
		this._formValues.country = value;

		if (value !== POSTCODE_COUNTRY) {
			this._formValues.state = "";
		}
	};
	setState = (value: string) => {
		this._formValues.state = value;
	};

	private navigateTo(to: string): void {
		if (!this._navigate) {
			return;
		}
		this._navigate(to);
	}
}
