import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {API_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	IJoinLeagueController,
	JoinLeagueController,
} from "views/pages/leagues/join_leagues/join_leagues.controller";
import {ILeagueApiProvider, LeagueApiProvider} from "data/providers/api/league.api.provider";
import {
	IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {INavController, NavController} from "views/components/Nav/nav.controller";
import {HelpController, IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {
	CreateLeagueController,
	ICreateLeagueController,
} from "views/pages/leagues/create_leagues/create_league.controller";
import {IModalsController, ModalsController} from "views/controllers/modals/modals.controller";
import {
	FormRegistrationController,
	IFormRegistrationController,
} from "views/components/forms/registration/form_registration.controller";
import {IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {FormValidationHelper, IFormValidationHelper} from "data/utils/form_validation_helper";
import {IMyLeaguesController, MyLeaguesController} from "views/pages/leagues/leagues.controller";
import {
	ILeagueCoreController,
	LeagueCoreController,
} from "views/pages/leagues/league/league_core/league_core.controller";
import {
	ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/leagues/league/league_about/league_about.controller";
import {
	ISettingsLeagueController,
	SettingsLeagueController,
} from "views/pages/leagues/league/league_settings/league_settings.controller";
import {
	IInviteLeagueController,
	InviteLeagueController,
} from "views/pages/leagues/league/league_invites/league_invites.controller";
import {
	ILeagueTableController,
	LeagueTableController,
} from "views/pages/leagues/league/league_table/league_table.controller";
import {
	FormLoginController,
	IFormLoginController,
} from "views/components/forms/login/form_login.controller";
import {
	ForgotPasswordFormController,
	IForgotPasswordFormController,
} from "views/components/forms/forgot_password_form/forgot_password_form.controller";
import {
	FormResetPasswordController,
	IFormResetPasswordController,
} from "views/components/forms/reset_password/form_reset_password.controller";
import {
	ContactUsController,
	IContactUsController,
} from "views/pages/help/contact_us/contact_us.controller";
import {ITitleStore, TitleStore} from "data/stores/title/title.store";
import {
	IMainLayoutController,
	MainLayoutController,
} from "views/components/main_layout/main_layout.controller";
import {INotFoundController, NotFoundController} from "views/pages/not_found/not_found.controller";
import {
	GameGateController,
	IGameGateController,
} from "views/components/session_gate/game_gate.controller";
import {FieldController, IFieldController} from "views/components/Field/field.controller";
import {ITeamStore, TeamStore} from "data/stores/team/team.store";
import {
	DriverPoolController,
	IDriverPoolController,
} from "views/components/DriverPool/driverPool.controller";
import {
	IPoolFiltersController,
	PoolFiltersController,
} from "views/components/DriverPool/PoolFilters/poolFilters.controller";
import {ITeamApiProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {ITeamController, TeamController} from "views/pages/team/team.controller";
import {
	DriverActionsController,
	IDriverActionsController,
} from "views/components/DriverActions/driverActions.controller";
import {
	FieldDriverController,
	IFieldDriverController,
} from "views/components/Player/fieldDriver.controller";
import {
	FieldActionsBarController,
	IFieldActionsBarController,
} from "views/components/FieldActionsBar/fieldActionsBar.controller";
import {DriversStore, IDriverStore} from "data/stores/driver/drivers.store";
import {INotificationService, NotificationService} from "data/services/Notification.service";
import {
	INotificationController,
	NotificationController,
} from "views/components/Notification/notification.controller";
import {
	DriverModalController,
	IDriverModalController,
} from "views/components/DriverModal/driverModal.controller";
import {
	HeadsLabelController,
	IHeadsLabelController,
} from "views/components/HeadsLabel/headsLabel.controller";
import {
	CreateLeagueStepOneFormController,
	ICreateLeagueStepOneFormController,
} from "views/pages/leagues/create_leagues/createLeagueStepOneForm/createLeagueStepOneForm.controller";
import {
	IRemoveLeagueUserModalController,
	RemoveLeagueUserModalController,
} from "views/components/RemoveLeagueUserModal/removeLeagueUserModal.controller";
import {
	ILeaveLeagueController,
	LeaveLeagueController,
} from "views/components/LeaveLeague/leaveLeague.controller";
import {
	DeleteOrLeaveLeagueModalController,
	IDeleteOrLeaveLeagueModalController,
} from "views/components/DeleteOrLeaveLeagueModal/deleteOrLeaveLeagueModal.controller";
import {ILockoutStore, LockoutStore} from "data/stores/lockout/lockout.store";
import {
	ITeamSocialSharingController,
	TeamSocialSharingController,
} from "views/components/TeamSocialSharing/teamSocialSharing.controller";
import {GamebarController, IGamebarController} from "views/components/GameBar/gamebar.controller";
import {
	INoHeadsSaveModalController,
	NoHeadsSaveModalController,
} from "views/components/NoHeadsSaveModal/noHeadsSaveModal.controller";
import {
	IRoundSelectorController,
	RoundSelectorController,
} from "views/components/RoundSelector/roundSelector.controller";
import {GamebarStore, IGamebarStore} from "data/stores/gamebar/gamebar.store";
import {
	ILeagueH2HMatchUpController,
	LeagueH2HMatchUpController,
} from "views/pages/leagues/league/league_h2h_matchup/league_h2h_matchup.controller";
import {IMatchupStore, MatchupStore} from "data/stores/matchup/matchup.store";
import {
	IMatchupCardController,
	MatchupCardController,
} from "views/components/MatchupCard/MatchupCard.controller";
import {
	ILeagueH2hMatchupViewController,
	LeagueH2hMatchupViewController,
} from "views/pages/leagues/league/league_h2h_matchup_view/league_h2h_matchup_view.controller";
import {
	IMatchupDriverToDriverController,
	MatchupDriverToDriverController,
} from "views/components/MatchupDriverToDriver/MatchupDriverToDriver.controller";
import {
	ITradeBarController,
	TradeBarController,
} from "views/components/trade_bar/trade_bar.controller";
import {
	ITradesActionsBarController,
	TradesActionsBarController,
} from "views/components/trades_actions_bar/trades_actions_bar.controller";
import {IRankingsApiProvider, RankingsApiProvider} from "data/providers/api/rankings.api.provider";
import {IRankingsController, RankingsController} from "views/pages/rankings/rankings.controller";
import {IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {
	INotificationBarController,
	NotificationBarController,
} from "views/components/notifications_bar/notification_bar.controller";
import {
	type IOffSeasonController,
	OffSeasonController,
} from "views/pages/off_season/off_season.controller";
import {
	IModalUserExistsController,
	ModalUserExistsController,
} from "views/components/modals/modal_user_exists/modal_user_exists.controller";
import {
	IModalTermsController,
	ModalTermsController,
} from "views/components/modals/modal_terms/modal_terms.controller";
import {
	ITeamStatsSelectController,
	TeamStatsSelectController,
} from "views/components/TeamStatsSelect/teamStatsSelect.controller";
import {
	IModalTeamSavedController,
	ModalTeamSavedController,
} from "views/components/modals/modal_team_saved/modal_team_saved.controller";
import {IStatesStore, StatesStore} from "data/stores/states/states.store";
import {
	ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	BootstrapController,
	IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {ILocalizationStore, LocalizationStore} from "data/stores/localization/localization.store";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {
	type ILiveUpdatesController,
	LiveUpdatesController,
} from "views/components/live_updates/live_updates.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<ILeagueApiProvider>(Bindings.LeagueApiProvider).to(LeagueApiProvider);
	bind<ITeamApiProvider>(Bindings.TeamApiProvider).to(TeamApiProvider);
	bind<IRankingsApiProvider>(Bindings.RankingsApiProvider).to(RankingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore).inSingletonScope();
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IStaticContentStore>(Bindings.StaticContentStore)
		.to(StaticContentStore)
		.inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<ITitleStore>(Bindings.TitleStore).to(TitleStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<IDriverStore>(Bindings.DriverStore).to(DriversStore).inSingletonScope();
	bind<INotificationService>(Bindings.NotificationService)
		.to(NotificationService)
		.inSingletonScope();
	bind<ILockoutStore>(Bindings.LockoutStore).to(LockoutStore).inSingletonScope();
	bind<IGamebarStore>(Bindings.GamebarStore).to(GamebarStore).inSingletonScope();
	bind<IMatchupStore>(Bindings.MatchupStore).to(MatchupStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<IStatesStore>(Bindings.StatesStore).to(StatesStore);
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<IJoinLeagueController>(Bindings.JoinLeagueController).to(JoinLeagueController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<ILeagueCoreController>(Bindings.LeagueCoreController).to(LeagueCoreController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ISettingsLeagueController>(Bindings.SettingsLeagueController).to(SettingsLeagueController);
	bind<IInviteLeagueController>(Bindings.InviteLeagueController).to(InviteLeagueController);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
	bind<INavController>(Bindings.NavController).to(NavController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IModalsController>(Bindings.ModalsController).to(ModalsController);
	bind<IFormRegistrationController>(Bindings.FormRegistrationController).to(
		FormRegistrationController
	);
	bind<IFormLoginController>(Bindings.FormLoginController).to(FormLoginController);
	bind<IFormValidationHelper>(Bindings.FormValidationHelper).to(FormValidationHelper);
	bind<IForgotPasswordFormController>(Bindings.ForgotPasswordFormController).to(
		ForgotPasswordFormController
	);
	bind<IFormResetPasswordController>(Bindings.FormResetPasswordController).to(
		FormResetPasswordController
	);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<IMainLayoutController>(Bindings.MainLayoutController).to(MainLayoutController);
	bind<INotFoundController>(Bindings.NotFoundController).to(NotFoundController);
	bind<IGameGateController>(Bindings.GameGateController).to(GameGateController);
	bind<IFieldController>(Bindings.FieldController).to(FieldController);
	bind<IDriverPoolController>(Bindings.DriverPoolController).to(DriverPoolController);
	bind<IPoolFiltersController>(Bindings.PoolFiltersController).to(PoolFiltersController);
	bind<ITeamController>(Bindings.TeamController).to(TeamController);
	bind<IGamebarController>(Bindings.GamebarController).to(GamebarController);
	bind<IDriverActionsController>(Bindings.DriverActionsController).to(DriverActionsController);
	bind<IFieldDriverController>(Bindings.FieldDriverController).to(FieldDriverController);
	bind<IFieldActionsBarController>(Bindings.FieldActionsBarController).to(
		FieldActionsBarController
	);
	bind<INotificationController>(Bindings.NotificationController).to(NotificationController);
	bind<IDriverModalController>(Bindings.DriverModalController).to(DriverModalController);
	bind<IHeadsLabelController>(Bindings.HeadsLabelController).to(HeadsLabelController);

	bind<ICreateLeagueStepOneFormController>(Bindings.CreateLeagueStepOneFormController).to(
		CreateLeagueStepOneFormController
	);
	bind<IRemoveLeagueUserModalController>(Bindings.RemoveLeagueUserModalController).to(
		RemoveLeagueUserModalController
	);
	bind<ILeaveLeagueController>(Bindings.LeaveLeagueController).to(LeaveLeagueController);
	bind<IDeleteOrLeaveLeagueModalController>(Bindings.DeleteOrLeaveLeagueModalController).to(
		DeleteOrLeaveLeagueModalController
	);
	bind<ITeamSocialSharingController>(Bindings.TeamSocialSharingController).to(
		TeamSocialSharingController
	);
	bind<INoHeadsSaveModalController>(Bindings.NoHeadsSaveModalController).to(
		NoHeadsSaveModalController
	);
	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<ILeagueH2HMatchUpController>(Bindings.LeagueH2HMatchUpController).to(
		LeagueH2HMatchUpController
	);
	bind<IMatchupCardController>(Bindings.MatchupCardController).to(MatchupCardController);
	bind<ILeagueH2hMatchupViewController>(Bindings.LeagueH2hMatchupViewController).to(
		LeagueH2hMatchupViewController
	);
	bind<IMatchupDriverToDriverController>(Bindings.MatchupDriverToDriverController).to(
		MatchupDriverToDriverController
	);
	bind<ITradeBarController>(Bindings.TradeBarController).to(TradeBarController);
	bind<ITradesActionsBarController>(Bindings.TradesActionsBarController).to(
		TradesActionsBarController
	);
	bind<IRankingsController>(Bindings.RankingsController).to(RankingsController);
	bind<INotificationBarController>(Bindings.NotificationBarController).to(
		NotificationBarController
	);
	bind<IOffSeasonController>(Bindings.OffSeasonController).to(OffSeasonController);
	bind<IModalTermsController>(Bindings.ModalTermsController).to(ModalTermsController);
	bind<ITeamStatsSelectController>(Bindings.TeamStatsSelectController).to(
		TeamStatsSelectController
	);
	bind<IModalUserExistsController>(Bindings.ModalUserExistsController).to(
		ModalUserExistsController
	);
	bind<IModalTeamSavedController>(Bindings.ModalTeamSavedController).to(ModalTeamSavedController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);

	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<ILiveUpdatesController>(Bindings.LiveUpdatesController).to(LiveUpdatesController);
});
