import {ViewController} from "data/types/structure";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {useNavigate} from "react-router-dom";
import type {ILeague} from "data/providers/api/league.api.provider";
import type {IUserStore} from "data/stores/user/user.store";
import {LeagueType, ModalType} from "data/enums";

interface IInitProps {
	leagueId?: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILeaveLeagueController extends ViewController<IInitProps> {
	handleLeagueLeave: () => void;

	get isActionInProcess(): boolean;

	get isOwner(): boolean;

	get isLeagueCanBeDeleted(): boolean;

	get isLeagueCanBeLeft(): boolean;
}

@injectable()
export class LeaveLeagueController implements ILeaveLeagueController {
	@observable private _leagueId: number | null = null;
	private _navigate: ReturnType<typeof useNavigate> | null = null;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isActionInProcess: boolean = false;

	get isActionInProcess(): boolean {
		return this._isActionInProcess;
	}

	get isOwner(): boolean {
		return this.league?.userId === this._userStore.user?.id;
	}

	get isLeagueCanBeDeleted(): boolean {
		if (!this.isLeagueH2H) {
			return !this._leaguesStore.getIsLeagueStarted(this.league);
		}
		return !this._leaguesStore.getIsH2HLeagueStarted(this.league);
	}

	get isLeagueCanBeLeft(): boolean {
		if (!this.isLeagueH2H) {
			return !this.isActionInProcess;
		}
		return !this._leaguesStore.getIsH2HLeagueStarted(this.league);
	}

	private get isLeagueH2H(): boolean {
		return this.league?.type === LeagueType.H2H;
	}

	private get league(): ILeague | undefined {
		if (!this._leagueId) {
			return;
		}
		return this._leaguesStore.selectedLeague;
	}

	handleLeagueLeave = () => {
		if (!this._leagueId) return;
		this._modalsStore.showModal(ModalType.LEAGUE_LEAVE, {
			leagueId: this._leagueId,
		});
	};

	init(param: IInitProps): void {
		if (param.leagueId) {
			this._leagueId = param.leagueId;
		}
		this._navigate = param.navigate;
	}

	dispose(): void {
		return;
	}

	private setInProcess(process: boolean): void {
		runInAction(() => {
			this._isActionInProcess = process;
		});
	}

	private navigateTo(to: string): void {
		if (!this._navigate) {
			return;
		}
		this._navigate(to);
	}
}
