import React, {Fragment, ReactNode} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import {IBootstrapController} from "views/components/bootstrap/bootstrap.controller";
import {Bindings} from "data/constants/bindings";
import {Preloader} from "views/components/Preloader";
import styled from "@emotion/styled";

const BootstrapWrapper = styled.div`
	height: 100vh;
	margin: auot;
`;
export const Bootstrap: React.FC<{children: ReactNode}> = observer(({children}) => {
	const {isReady} = useViewController<IBootstrapController>(Bindings.BootstrapController);
	return isReady ? (
		<Fragment>{children}</Fragment>
	) : (
		<BootstrapWrapper>
			<Preloader />
		</BootstrapWrapper>
	);
});
