import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeague} from "data/providers/api/league.api.provider";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {LeaguePrivacy, LeagueType} from "data/enums";

interface IInit {
	leagueId: number;
}

export interface ILeagueCoreController extends ViewController<IInit> {
	get leagues(): ILeague[];

	get selectedLeague(): ILeague | undefined;

	get user(): IUser | undefined;

	get isH2HStarted(): boolean;

	// get isLeagueRegular(): boolean;

	get isLeaguePrivate(): boolean;

	get isCommissioner(): boolean;

	get isNavVisible(): boolean;

	get isLoaded(): boolean;
}

@injectable()
export class LeagueCoreController implements ILeagueCoreController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return !this._leaguesStore.isSingleLeagueLoading;
	}

	get leagues() {
		return this._leaguesStore.list;
	}

	get selectedLeague() {
		return this._leaguesStore.selectedLeague;
	}

	get user() {
		return this._userStore.user;
	}

	get isH2HStarted(): boolean {
		return this._leaguesStore.getIsH2HLeagueStarted(this.selectedLeague);
	}

	get isCommissioner(): boolean {
		return this.selectedLeague?.userId === this.user?.id;
	}

	get isLeaguePrivate(): boolean {
		return this.selectedLeague?.privacy === LeaguePrivacy.Private;
	}

	get isLeagueRegular(): boolean {
		return this.selectedLeague?.type !== LeagueType.H2H;
	}

	// get isLeagueClassic(): boolean {
	// 	return this.selectedLeague?.type !== LeagueType.CLASSIC;
	// }

	get isNavVisible(): boolean {
		return Boolean(this.selectedLeague);
	}

	dispose(): void {
		return;
	}

	init(params: IInit): void {
		void this._leaguesStore.getLeague({leagueId: params.leagueId});
		void this._roundStore.fetchRounds();
	}
}
