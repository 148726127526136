import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IDriverStore} from "data/stores/driver/drivers.store";
import {type IMatchupStore} from "data/stores/matchup/matchup.store";
import {type IGamebarStore} from "data/stores/gamebar/gamebar.store";
import {ViewController} from "data/types/structure";
import {RoundStatus} from "data/enums";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

type IChecksumAction = Record<keyof IChecksums, () => Promise<unknown>>;

interface IProps {
	roundId?: number;
	leagueId?: number;
	pageType?: "team" | "matchup";
}

export interface ILiveUpdatesController extends ViewController<IProps> {
	subscribeLiveUpdate: () => void;
	unsubscribeLiveUpdate: () => void;
}

@injectable()
export class LiveUpdatesController implements ILiveUpdatesController {
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _liveUpdatesDisposer?: ReturnType<typeof reaction>;
	@observable protected _changesDisposer?: ReturnType<typeof reaction>;
	@observable protected _isSubscribed: boolean = false;
	@observable protected _roundId?: number;
	@observable protected _pageType?: "matchup" | "team";

	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.MatchupStore) private _matchupStore: IMatchupStore,
		@inject(Bindings.GamebarStore) private _gamebarStore: IGamebarStore
	) {
		makeAutoObservable(this);
	}

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private get actions(): IChecksumAction {
		const id = this._roundId;
		if (!id) return {};

		return {
			drivers: () => this._driverStore.fetchDrivers(),
			rounds: () => this._roundsStore.fetchRounds(),
		};
	}

	public fetchMatchup = (round?: number): void => {
		void this._matchupStore.fetchMatchups(this._roundId);
	};

	@computed private get changes() {
		return Object.keys(this._checksumStore.changedChecksums);
	}

	@computed private get round() {
		if (!this._roundId) return;
		return this._roundsStore.getRoundById(this._roundId);
	}

	@computed private get isLive() {
		return this.round?.status === RoundStatus.Playing;
	}

	@computed get isTeamPage() {
		return this._pageType === "team";
	}

	@computed get isMatchupPage() {
		return this._pageType === "matchup";
	}

	@action
	public subscribeLiveUpdate() {
		if (this._isSubscribed) return;

		this._isSubscribed = true;
		void this._checksumStore.fetchChecksums();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveUpdate() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	private callActions = () => {
		this.changes.forEach((key) => {
			const action = this.actions[key];
			if (action && typeof action === "function") {
				void action();
			}

			if (key === "rounds") {
				if (this.isMatchupPage) {
					void this.fetchMatchup();
				} else if (this.isTeamPage) {
					void this._gamebarStore.fetchGamebar();
				}
			}
		});
	};

	dispose(): void {
		this._liveUpdatesDisposer?.();
		this._changesDisposer?.();
		this.unsubscribeLiveUpdate();
	}

	@action onChange({roundId, pageType}: IProps) {
		this._roundId = roundId ? Number(roundId) : undefined;
		this._pageType = pageType;
	}

	init({roundId, pageType}: IProps): void {
		if (!roundId) return;
		this._roundId = Number(roundId);
		this._pageType = pageType;

		this._liveUpdatesDisposer = reaction(
			() => this.isLive,
			() => {
				this.unsubscribeLiveUpdate();

				if (this.isLive) {
					this.subscribeLiveUpdate();
				}
			},
			{fireImmediately: true}
		);

		this._changesDisposer = reaction(() => this.changes, this.callActions, {
			fireImmediately: true,
		});
	}
}
