import {ViewController} from "data/types/structure";
import {IDriver, IDriverPriceChange} from "data/types/team";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IDriverStore} from "data/stores/driver/drivers.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {HeadsPosition, ModalType, RoundStatus} from "data/enums";
import {makeAutoObservable, observable, toJS} from "mobx";
import React from "react";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {takeRight} from "lodash";

interface IProps {
	driverId: number;
}

export interface IDriverModalController extends ViewController {
	closeModal: (event: unknown, reason: "backdropClick" | "escapeKeyDown") => void;
	closeModalButton: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	removeFromTeam: () => void;
	addDriverToTeam: () => void;
	setAsCaptain: () => void;
	setAsViceCaptain: () => void;
	addToSwap: () => void;
	swap: () => void;

	get driver(): IDriver | undefined;
	get modalText(): string;

	get driverCostChangeEntity(): IDriverPriceChange | undefined;

	get isModalOpen(): boolean;

	get isDriverInTeam(): boolean;

	get isDriverCaptain(): boolean;

	get isDriverViceCaptain(): boolean;

	get canBeSetAsHeads(): boolean;

	get isPlayerActionsDisabled(): boolean;

	get actualRoundPoints(): string | number;

	get lastRacesResult(): string;

	get isTeamScoring(): boolean;

	get isActionsLocked(): boolean;

	get isSwapEnabled(): boolean;

	get isOnSwap(): boolean;
	get isSwapInProgress(): boolean;
	get isLockout(): boolean;
}

@injectable()
export class DriverModalController implements IDriverModalController {
	@observable private _ModalText: string = "";

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get modalText(): string {
		return this._ModalText;
	}

	get driver(): IDriver | undefined {
		const content = this._modalStore.modalContent as IProps;
		if (!content) {
			return;
		}
		return this._driverStore.getDriverById(content.driverId);
	}

	get driverCostChangeEntity() {
		if (!this.driver || !this._roundsStore.currentRound) {
			return undefined;
		}

		return this._driverStore.getDriverPriceChanges(
			this.driver.id,
			this._roundsStore.currentRound.id
		);
	}

	get isModalOpen() {
		return this._modalStore.modal === ModalType.DRIVER_PROFILE;
	}

	get canBeSetAsHeads(): boolean {
		if (!this.isDriverInTeam || this._teamStore.getIsDriverInReserve(this.driver?.id)) {
			return false;
		}
		return !this.isActionsLocked;
	}

	get isDriverInTeam(): boolean {
		if (!this.driver) {
			return false;
		}
		return this._teamStore.getIsDriverInTeam(this.driver.id);
	}

	get isDriverCaptain(): boolean {
		return this._teamStore.team.lineup.captain === this.driver?.id;
	}

	get isDriverViceCaptain(): boolean {
		return this._teamStore.team.lineup.viceCaptain === this.driver?.id;
	}

	get isTeamFullFilled(): boolean {
		const drivers = this._teamStore.getDriversInTeam;
		return drivers.every((e) => e !== 0);
	}

	get actualRoundPoints(): string | number {
		if (!this.driver) {
			return "-";
		}

		return this._driverStore.getPoints({driver: this.driver});
	}

	get lastRacesResult(): string {
		const places = this.driver?.places || [];
		return takeRight(places, 3).join(",") || "-";
	}

	public get isPlayerActionsDisabled(): boolean {
		if (!this.driver) {
			return true;
		}
		return (
			this.isCostMoreThanSalaryCap(this.driver) ||
			this.isTeamFullFilled ||
			this.isRowFull(this.driver)
		);
	}

	public get isLockout() {
		return this._teamStore.isTeamInLockout;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public closeModalButton = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		this._modalStore.hideModal();
	};
	public closeModal = (event: unknown, reason: "backdropClick" | "escapeKeyDown") => {
		if (reason && reason === "backdropClick") return;
		this._modalStore.hideModal();
	};

	removeFromTeam = (): void => {
		if (!this.driver) {
			return;
		}
		this._teamStore.removeDriverFromTeam(this.driver.id);
	};

	public addDriverToTeam = (): void => {
		if (!this.driver) {
			return;
		}
		this._teamStore.addDriverToTeam(this.driver.id);
	};

	setAsCaptain = (): void => {
		const isCaptain = this._teamStore.getIsDriverRole(this.driver?.id, HeadsPosition.Captain);

		if (isCaptain) {
			//this._ModalText = "Captain removed!";
			this._teamStore.removeRole(HeadsPosition.Captain);
		} else {
			//this._ModalText = "Captain saved!";
			this._teamStore.setAsCaptain(this.driver?.id);
			//void this._teamStore.saveTeam();
		}
	};

	setAsViceCaptain = (): void => {
		const isViceCaptain = this._teamStore.getIsDriverRole(
			this.driver?.id,
			HeadsPosition.ViceCaptain
		);

		if (isViceCaptain) {
			//this._ModalText = "Vice captain removed!";
			this._teamStore.removeRole(HeadsPosition.ViceCaptain);
		} else {
			//this._ModalText = "Vice captain saved!";
			this._teamStore.setAsViceCaptain(this.driver?.id);
			//void this._teamStore.saveTeam();
		}
	};

	isCostMoreThanSalaryCap(driver: IDriver): boolean {
		if (!driver) {
			return true;
		}

		return driver.cost > this._teamStore.salaryCap;
	}

	isRowFull(driver: IDriver): boolean {
		if (!driver) {
			return true;
		}
		const row = this._teamStore.getLineupForRow(driver.regionId);
		return row.every((e) => e !== 0);
	}

	get isTeamScoring() {
		return this._teamStore.isTeamScoring;
	}

	get isActionsLocked() {
		return (
			this.isTeamScoring && this._roundsStore.selectedRound?.status !== RoundStatus.Scheduled
		);
	}

	get isSwapEnabled() {
		return (
			this.isTeamScoring &&
			this._roundsStore.selectedRound?.status === RoundStatus.Scheduled &&
			this.isDriverInTeam
		);
	}

	addToSwap = () => {
		if (this.driver) {
			this._teamStore.addToSwap(this.driver);

			this._modalStore.hideModal();
		}
	};

	get isSwapInProgress(): boolean {
		return this._teamStore.isSwapInProgress;
	}

	get isOnSwap(): boolean {
		if (!this.driver) {
			return false;
		}
		return this._teamStore.isOnSwap(this.driver);
	}

	swap = () => {
		if (!this.driver) {
			return false;
		}

		this._teamStore.swapDriver(toJS(this.driver));
		this._modalStore.hideModal();
	};
}
