import styled from "@emotion/styled";
import media from "assets/css/media";
import {NavLink} from "react-router-dom";

export const PageTitle = styled.h2`
	font-size: 26px;
	line-height: 32px;
	font-weight: bold;
	color: #1e1450;
	margin-bottom: 17px;

	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 22px;
	}
`;

export const Container = styled.div`
	max-width: 1440px;
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
	box-sizing: border-box;

	@media (max-width: 840px) {
		padding: 0;
		//	padding: 0 20px;
		//	width: 100%;
		//	background: inherit;
		//	display: flex;
		//	flex-direction: column;
		//	align-items: center;
	}
`;

export const PureContainer = styled.div`
	max-width: 1440px;
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
	box-sizing: border-box;
`;

export const ContentNavItem = styled(NavLink)`
	color: #757575;
	width: 100%;
	font-size: 16px;
	font-family: Open Sans;
	font-weight: 400;
	height: 40px;
	line-height: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #c7c7cc;

	&.active {
		color: #00689f;
		border-bottom: 2px solid #00689f;
	}

	@media (max-width: ${media.tablet}) {
		padding: 7px 12px;
		font-size: 14px;
		line-height: 16px;
	}
`;

export const Relative = styled.div`
	position: relative;
`;

export const HeadingLabel = styled.div`
	width: 20px;
	height: 20px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;

	&.captain {
		background: ${({theme}) => theme.driverProfile.actions.captain};
	}

	&.vice-captain {
		background: ${({theme}) => theme.driverProfile.actions.viceCaptain};
	}
`;
