import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {AdSideLayout, LandingLayout, MainLayoutAuth} from "views/components/main_layout";

const Home = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const OffSeason = lazy(retryFailLoad(() => import("views/pages/off_season/off_season.page")));
const TeamPage = lazy(retryFailLoad(() => import("views/pages/team/team.page")));
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const JoinLeague = lazy(
	retryFailLoad(() => import("views/pages/leagues/join_leagues/join_leagues.page"))
);
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/leagues/create_leagues/create_league.page"))
);
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const Leagues = lazy(retryFailLoad(() => import("views/pages/leagues/leagues.page")));
const LeagueCore = lazy(
	retryFailLoad(() => import("views/pages/leagues/league/league_core/league_core"))
);
const LeagueAbout = lazy(
	retryFailLoad(() => import("views/pages/leagues/league/league_about/league_about.page"))
);
const LeagueInvites = lazy(
	retryFailLoad(() => import("views/pages/leagues/league/league_invites/league_invites.page"))
);
const LeagueTable = lazy(
	retryFailLoad(() => import("views/pages/leagues/league/league_table/league_table.page"))
);
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/leagues/league/league_settings/league_settings.page"))
);

const LeagueH2hMathups = lazy(
	retryFailLoad(
		() => import("views/pages/leagues/league/league_h2h_matchup/league_h2h_matchup.page")
	)
);

const LeagueH2hMathupsView = lazy(
	retryFailLoad(
		() =>
			import(
				"views/pages/leagues/league/league_h2h_matchup_view/league_h2h_matchup_view.page"
			)
	)
);

const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const RankingsPage = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const ReturningUserPage = lazy(
	retryFailLoad(() => import("views/pages/returning_user_journey/returning_user.page"))
);

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route path={"/*"} element={<LandingLayout />}>
			<Route element={<NotAuthOnlyRoute />}>
				<Route index element={<Home />} />
				<Route path="off-season" element={<OffSeason />}></Route>
			</Route>
		</Route>
		<Route path={"/*"} element={<AdSideLayout />}>
			<Route path="help/*" element={<Help />} />
			<Route path={"reset-password"} element={<ResetPassword />} />
		</Route>
		<Route path={"/*"} element={<MainLayoutAuth />}>
			<Route element={<PrivateRoute />}>
				<Route path="my-account" element={<MyAccount />} />
				<Route path="leagues">
					<Route index element={<Leagues />} />
					<Route path="join" element={<JoinLeague />} />
					<Route path="join/:leagueCode" element={<JoinLeague />} />
					<Route path="create" element={<CreateLeague />} />
					<Route path=":id" element={<LeagueCore />}>
						<Route path="table" element={<LeagueTable />} />
						<Route path="invites" element={<LeagueInvites />} />
						<Route path="about" element={<LeagueAbout />} />
						<Route path="settings" element={<LeagueSettings />} />
						<Route path="h2h/:roundId/:index/view" element={<LeagueH2hMathupsView />} />
						<Route path="h2h" element={<LeagueH2hMathups />} />
					</Route>
				</Route>
				<Route path="team" element={<TeamPage />} />
				<Route path="my-team" element={<TeamPage />} />
				<Route path="standings" element={<RankingsPage />} />
				<Route path="returning-user" element={<ReturningUserPage />} />
			</Route>

			<Route path="*" element={<NotFound />} />
		</Route>
	</Routes>
);

export default RootRoutes;
